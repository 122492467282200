<template>
  <div class="loading-wrapper" :class="{'btn-load': button}">
    <img
      v-if="loading"
      id="logo"
      :class="{'loading': !button}"
      :src="image_path"
      alt="Loading"
    />
  </div>
</template>

<script>
import ColoredLoading from '@/assets/images/CORE_LOGO_color.png';
import Loading from '@/assets/images/CORE_LOGO.png';

export default {
  name: 'Loading',
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    color: {
      default: 'red',
      required: false,
    },
    button: {
      required: false,
      default: false,
    },
  },
  computed: {
    image_path() {
      if (this.color === 'red') {
        return ColoredLoading;
      }
      return Loading;
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.btn-load {
  width: fit-content;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  #logo {
   width: auto !important;
   height: 100% !important;
   padding: 3px;
  }
}
@keyframes loading {
  0% {
    opacity: 0.2;
    width: 50px;
    height: 50px;
    transform: rotate(-360deg);

  }
  25% {
  }
  50% {
    opacity: 1;
    width: 55px;
    height: 55px;
    transform: rotate(360deg);
  }
  75% {
  }
  100% {
    opacity: 0.2;
    width: 50px;
    height: 50px;
    transform: rotate(-360deg);
  }
}

#logo {
  animation-name: loading;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}
</style>
