import { currencySymbol } from '@/ort-lib/utils/formatters';

// replace
export const setAmount = function (amount, currency) {
  const fixedNumber = amount.toString().split('.')[0];
  const currencySign = currencySymbol(currency);

  let positionToCut = '';
  let unit = '';
  let finalNum = '';
  if (fixedNumber.length > 9) {
    positionToCut = fixedNumber.length - 8;
    unit = 'B';
  } else if (fixedNumber.length > 6) {
    positionToCut = fixedNumber.length - 5;
    unit = 'M';
  } else if (fixedNumber.length > 3) {
    positionToCut = fixedNumber.length - 2;
    unit = 'K';
  } else {
    return currencySign + fixedNumber;
  }
  const absolutePlusDecimal = fixedNumber.slice(0, positionToCut);
  const absoluteLastDecimalNum = absolutePlusDecimal[absolutePlusDecimal.length - 1];
  const decimalIsZero = absoluteLastDecimalNum == '0';
  if (decimalIsZero) {
    finalNum = absolutePlusDecimal.slice(0, absolutePlusDecimal.length - 1) + unit;
  } else {
    finalNum = `${absolutePlusDecimal.slice(
      0,
      absolutePlusDecimal.length - 1,
    )}.${absoluteLastDecimalNum}${unit}`;
  }
  return currencySign + finalNum;
};

// leave
export const getPercentageChange = (newNumber, oldNumber) => {
  let percentageNumber = null;
  if (oldNumber == 0 && newNumber > 0) {
    return 100;
  }
  if (oldNumber == 0 && newNumber == 0) {
    return 0;
  }

  const amountDifference = newNumber - oldNumber;
  percentageNumber = (amountDifference / oldNumber) * 100;
  percentageNumber = Math.round((percentageNumber + Number.EPSILON) * 100) / 100;

  return percentageNumber;
};

// replaced
export const formatNumber = (value) => {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return formatter.format(value);
};

// leave
export const truncate = (str, n) => ((str && str.length > n) ? `${str.substr(0, n - 1)}...` : str);

// leave
export const getTotal = (arr, field) => arr.reduce((accumulator, current) => accumulator + current[field], 0);

// leave
export const getMediumValue = (arr, field) => {
  if (arr.length > 0) {
    const sum = getTotal(arr, field);
    const mediumValue = sum / arr.length;
    return mediumValue;
  }
  return null;
};
