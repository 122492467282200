// Store mutations

import VueCookie from 'vue-cookies';

export const mutations = {
  saveUserToken(state, data) {
    state.api.token = data.token;
  },
  saveUserInfos(state, logInfos) {
    state.email = logInfos.email;
    state.id = logInfos.id; // deprecated. use jwt payload instead
    state.name = `${logInfos.first_name} ${logInfos.last_name}`;
    state.profile_image = logInfos.profile_image;
    state.groups = logInfos.groups;
    state.cache_data = localStorage.getItem('cachedata');
  },
  removeUserInfos(state) {
    state.email = null;
    state.name = null;
    state.username = null;
    state.groups = null;
    state.api.token = null;
  },
  setLoggedIn(state, logged_in) {
    state.logged_in = logged_in;
  },
  cacheData(state, data) {
    if (state.cache_data === null) {
      state.cache_data = {};
    }
    state.cache_data[data.index] = data.data;
    localStorage.setItem('cachedata', this.state.cache_data, state.expiry.local_storage);
  },
  changeAlertInfos(state, alert) {
    state.alert.show = alert.show;
    state.alert.type = alert.type;
    state.alert.message = alert.message;
  },

};
