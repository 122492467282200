import { axiosCore } from '@/plugins/axios';

const state = {
  events: [],
  isEventsLoading: false,
};

const getters = {
  getEvents: (state) => state.events,
  getIsLoading: (state) => state.isEventsLoading,
};

const actions = {
  async fetchEvents({ commit, dispatch }, searchParam) {
    const url = `events/investment?${searchParam}`;
    commit('setIsEventsLoading', true);
    let response;
    try {
      response = await axiosCore.get(url);
      commit('setEvents', response.data);
    } catch (error) {
      dispatch('dangerAlert', error.message);
    } finally {
      commit('setIsEventsLoading', false);
      return response;
    }
  },

  async fetchEvent({ commit, dispatch }, eventId) {
    const url = `events/investment/${eventId}`;
    commit('setIsEventsLoading', true);
    let response;
    try {
      response = await axiosCore.get(url);
      commit('setEvents', response.data);
    } catch (error) {
      dispatch('dangerAlert', error.message);
    } finally {
      commit('setIsEventsLoading', false);
      return response;
    }
  },

  async updateEvent({ commit, dispatch }, { eventId, payload }) {
    const url = `events/investment/${eventId}`;
    commit('setIsEventsLoading', true);
    try {
      await axiosCore.patch(url, payload);
    } catch (error) {
      dispatch('dangerAlert', error.message);
    } finally {
      commit('setIsEventsLoading', false);
    }
  },
};

const mutations = {
  setEvents: (state, data) => { state.events = data; },
  setIsEventsLoading: (state, data) => { state.isEventsLoading = data; },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
