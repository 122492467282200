/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import { axiosCore } from '@/plugins/axios';

const state = {
  partner: {},
  partnerFull: {},
  isPartnerLoading: false,
  partnerTypeChoices: [],
  arePartnerTypeChoicesLoading: false,
  partnerStatusChoices: [],
  arePartnerStatusChoicesLoading: false,
  isPartnerRelationshipsLoading: false,
  partnerRelationships: [],
  isPartnerContractInfoLoading: false,
  partnerContractInfo: {},
  isPartnerTableLoading: false,
  partnerTable: [],
  isPartnerFeesLoading: false,
  partnerFees: {},
  isPartnerInvestorTableLoading: false,
  partnerInvestorTable: [],
  partnerInvestmentsTable: [],
  isPartnerInvestmentsTableLoading: 0,
  isFundTableLoading: false,
  fundTable: [],
  isPartnerInvestorOverviewLoading: false,
  partnerInvestorOverview: {},
  allInvestmentsLoading: false,
  partnerInvestorAllInvestments: [],
  isAddingInvestor: false,
  partnerRelatedManagers: [],
  isRelatedManagersLoading: false,
  partnerRelatedUsers: [],
  partnerProfileImage: '',
};

const getters = {
  getPartnerFullInfo: (state) => state.partnerFull,
  getPartnerRelatedUsers: (state) => state.partnerRelatedUsers,
  getPartnerProfileImage: (state) => state.partnerProfileImage,
  getPartnerTypeChoices: (state) => state.partnerTypeChoices
    .map(({ text, value }) => ({
      value,
      text,
      label: text,
    })),
  getPartnerStatusChoices: (state) => state.partnerStatusChoices
    .map(({ text, value }) => ({
      value,
      text,
      label: text,
    })),
  isContractInfoLoading: (state) => state.isPartnerContractInfoLoading,
  partnerContractInfo: (state) => state.partnerContractInfo,
  isAllInvestmentsLoading: (state) => state.allInvestmentsLoading,
  partnerInvestorAllInvestments: (state) => state.partnerInvestorAllInvestments,
  isAddingInvestor: (state) => state.isAddingInvestor,
  getPartnerRelatedManagers: (state) => state.partnerRelatedManagers,
  isRelatedManagersLoading: (state) => state.isRelatedManagersLoading,
};

const actions = {
  async fetchPartner({ commit }, partnerId) {
    commit('setIsPartnerLoading', true);
    try {
      const response = await axiosCore.get(`partners/${partnerId}/basic-info`);
      commit('setPartner', response.data);
    } catch (error) {
      throw new Error(error.message);
    } finally {
      commit('setIsPartnerLoading', false);
    }
  },
  async updatePartner({ commit }, { partnerId, formData }) {
    commit('setIsPartnerLoading', true);
    const payload = {
      partnerId,
      ...formData,
    };

    try {
      const response = await axiosCore.patch(`partners/${partnerId}/basic-info`, payload);
      commit('setPartner', response.data);
    } catch (error) {
      throw new Error(error.message);
    } finally {
      commit('setIsPartnerLoading', false);
    }
  },
  async fetchPartnerRelationships({ commit }, entityId) {
    commit('setIsPartnerRelationshipsLoading', true);
    try {
      const response = await axiosCore.get(`partners/${entityId}/partner-relationships`);
      commit('setPartnerRelationships', response.data);
    } catch (error) {
      throw new Error(error.message);
    } finally {
      commit('setIsPartnerRelationshipsLoading', false);
    }
  },
  async patchPartnerRelationships({ commit, dispatch }, { partnerId, body }) {
    commit('setIsPartnerRelationshipsLoading', true);
    try {
      for (const [relationshipId, payload] of Object.entries(body)) {
        const response = await axiosCore.patch(`partners/${partnerId}/partner-relationships/${relationshipId}`, payload);
        commit('setPartnerRelationshipsRow', response.data);
      }
      dispatch('successAlert', 'Partner relationship has been successfully updated!');
    } catch (error) {
      dispatch('dangerAlert', 'There was an error updating the partner relationship.');
      throw new Error(error.message);
    } finally {
      commit('setIsPartnerRelationshipsLoading', false);
    }
  },
  async fetchPartnerContractInfo({ commit, dispatch }, partnerId) {
    commit('setIsPartnerContractInfoLoading', true);
    try {
      const response = await axiosCore.get(`partners/${partnerId}/contract-info`);
      commit('setPartnerContractInfo', response.data);
    } catch (error) {
      dispatch('dangerAlert', 'There was an error retrieving the contract information.');
      throw new Error(error.message);
    } finally {
      commit('setIsPartnerContractInfoLoading', false);
    }
  },
  async patchContractInfo({ commit, dispatch }, { partnerId, formData }) {
    commit('setIsPartnerContractInfoLoading', true);
    try {
      const response = await axiosCore.patch(`partners/${partnerId}/contract-info`, formData);
      dispatch('successAlert', 'Contract information has been successfully updated!');
      commit('setPartnerContractInfo', response.data);
    } catch (error) {
      dispatch('dangerAlert', 'There was an error updating the contract information.');
      throw new Error(error.message);
    } finally {
      commit('setIsPartnerContractInfoLoading', false);
    }
  },
  async fetchPartnerTable({ commit }, entityId) {
    commit('setIsPartnerTableLoading', true);
    try {
      const response = await axiosCore.get(`partners/${entityId}/partner-clients-info`);
      commit('setPartnerTable', response.data);
    } catch (error) {
      throw new Error(error.message);
    } finally {
      commit('setIsPartnerTableLoading', false);
    }
  },
  async fetchPartnerFees({ commit }, entityId) {
    commit('setIsPartnerFeesLoading', true);
    try {
      const response = await axiosCore.get(`partners/${entityId}/fees-info`);
      commit('setPartnerFees', response.data);
    } catch (error) {
      throw new Error(error.message);
    } finally {
      commit('setIsPartnerFeesLoading', false);
    }
  },
  async patchPartnerFees({ commit, dispatch }, { partnerId, payload }) {
    commit('setIsPartnerFeesLoading', true);
    try {
      const response = await axiosCore.patch(`partners/${partnerId}/fees-info`, payload);
      dispatch('successAlert', 'Partner fees has been successfully updated!');
      commit('setPartnerFees', response.data);
    } catch (error) {
      dispatch('dangerAlert', 'There was an error updating the partner fees.');
      throw new Error(error.message);
    } finally {
      commit('setIsPartnerFeesLoading', false);
    }
  },
  async fetchPartnerInvestorTable({ commit }, entityId) {
    commit('setIsPartnerInvestorTableLoading', true);
    commit('setPartnerInvestorTable', []);
    try {
      const response = await axiosCore.get(`partners/${entityId}/investor-relationships`);
      commit('setPartnerInvestorTable', response.data);
    } catch (error) {
      throw new Error(error.message);
    } finally {
      commit('setIsPartnerInvestorTableLoading', false);
    }
  },
  async patchPartnerInvestorTable({ commit, dispatch }, { partnerId, body }) {
    commit('setIsPartnerInvestorTableLoading', true);
    try {
      for (const [relationshipId, payload] of Object.entries(body)) {
        const response = await axiosCore.patch(`partners/${partnerId}/investor-relationships/${relationshipId}`, payload);
        commit('setPartnerInvestorTableRow', response.data);
      }
      dispatch('successAlert', 'Investor table has been successfully updated!');
    } catch (error) {
      dispatch('dangerAlert', 'There was an error updating the contract information.');
      throw new Error(error.message);
    } finally {
      commit('setIsPartnerInvestorTableLoading', false);
    }
  },
  async fetchFundTable({ commit }, entityId) {
    commit('setIsFundTableLoading', true);
    try {
      const response = await axiosCore.get(`partners/${entityId}/fees`);
      commit('setFundTable', response.data);
    } catch (error) {
      throw new Error(error.message);
    } finally {
      commit('setIsFundTableLoading', false);
    }
  },
  async fetchPartnerInvestmentsTable({ commit }, entityId) {
    commit('setIsPartnerInvestmentsTableLoading', true);
    try {
      const response = await axiosCore.get(`partners/${entityId}/investments-kpis`);
      commit('setPartnerInvestmentsTable', response.data);
    } catch (error) {
      throw new Error(error.message);
    } finally {
      commit('setIsPartnerInvestmentsTableLoading', false);
    }
  },
  async patchFundTable({ commit, dispatch }, { partnerId, body }) {
    commit('setIsFundTableLoading', true);
    try {
      for (const [feeId, payload] of Object.entries(body)) {
        const response = await axiosCore.patch(`partners/${partnerId}/fees/${feeId}`, payload);
        commit('setFundTableRow', response.data);
      }
      dispatch('successAlert', 'Fund table has been successfully updated!');
    } catch (error) {
      dispatch('dangerAlert', 'There was an error updating the fund table.');
      throw new Error(error.message);
    } finally {
      commit('setIsFundTableLoading', false);
    }
  },
  async fetchPartnerInvestorOverview({ commit }, entityId) {
    commit('setIsPartnerInvestorOverviewLoading', true);
    try {
      const response = await axiosCore.get(`partners/${entityId}/investor-overview`);
      commit('setPartnerInvestorOverview', response.data[0]);
    } catch (error) {
      throw new Error(error.message);
    } finally {
      commit('setIsPartnerInvestorOverviewLoading', false);
    }
  },
  async fetchInvestorPartnerInfo({ commit }, { partnerId, investorId }) {
    commit('setAllInvestmentsLoading', true);
    commit('setPartnerInvestorAllInvestments', []);
    const url = `partners/${partnerId}/investors/${investorId}/partner-info`;
    try {
      const response = await axiosCore.get(url);
      commit('setPartnerInvestorAllInvestments', response.data);
    } catch (error) {
      throw new Error(error.message);
    } finally {
      commit('setAllInvestmentsLoading', false);
    }
  },
  async addInvestorToPartner({ commit }, { partnerId, investorId, managerId }) {
    const url = `/partners/${partnerId}/investors/${investorId}/managers/${managerId}/create-relations`;
    try {
      commit('setIsAddingInvestor', true);
      await axiosCore.post(url);
    } catch (error) {
      throw new Error(error.message);
    } finally {
      commit('setIsAddingInvestor', false);
    }
  },
  async fetchRelatedManagers({ commit }, entityId) {
    commit('setIsRelatedManagersLoading', true);
    commit('setRelatedManagers', []);
    try {
      const response = await axiosCore.get(`partners/${entityId}/related_managers`);
      commit('setRelatedManagers', response.data);
    } catch (error) {
      throw new Error(error.message);
    } finally {
      commit('setIsRelatedManagersLoading', false);
    }
  },
  async fetchPartnerFullInfo({ commit, dispatch }, partnerId) {
    commit('setIsPartnerLoading', true);
    try {
      const response = await axiosCore.get(`partners/${partnerId}/`);
      commit('setPartnerFull', response.data);
      commit('setPartnerRelatedUsers', response.data.related_users);
      commit('setPartnerProfileImage', response.data.profile_image);
    } catch (error) {
      throw new Error(error.message);
    } finally {
      commit('setIsPartnerLoading', false);
    }
  },
};

const mutations = {
  setPartnerProfileImage: (state, payload) => { state.partnerProfileImage = payload; },
  setPartnerRelatedUsers: (state, payload) => { state.partnerRelatedUsers = payload; },
  setPartnerFull: (state, payload) => { state.partnerFull = payload; },
  setPartner: (state, payload) => { state.partner = payload; },
  setIsPartnerLoading: (state, payload) => { state.isPartnerLoading = payload; },
  setPartnerTypeChoices: (state, payload) => { state.partnerTypeChoices = payload; },
  setPartnerStatusChoices: (state, payload) => { state.partnerStatusChoices = payload; },
  setArePartnerTypeChoicesLoading: (state, payload) => {
    state.arePartnerTypeChoicesLoading = payload;
  },
  setArePartnerStatusChoicesLoading: (state, payload) => {
    state.arePartnerStatusChoicesLoading = payload;
  },
  setIsPartnerRelationshipsLoading: (state, payload) => {
    state.isPartnerRelationshipsLoading = payload;
  },
  setPartnerRelationships: (state, payload) => { state.partnerRelationships = payload; },
  setPartnerRelationshipsRow: (state, payload) => {
    const row = state.partnerRelationships.findIndex((el) => el.id == payload.id);
    for (const [key, value] of Object.entries(payload)) {
      state.partnerRelationships[row][key] = value;
    }
  },
  setIsPartnerContractInfoLoading: (state, boolean) => {
    state.isPartnerContractInfoLoading = boolean;
  },
  setPartnerContractInfo: (state, payload) => { state.partnerContractInfo = payload; },
  setIsPartnerTableLoading: (state, payload) => { state.isPartnerTableLoading = payload; },
  setPartnerTable: (state, payload) => { state.partnerTable = payload; },
  setPartnerFees: (state, payload) => { state.partnerFees = payload; },
  setIsPartnerFeesLoading: (state, payload) => { state.isPartnerFeesLoading = payload; },
  setIsPartnerInvestorTableLoading: (state, payload) => { state.isPartnerInvestorTableLoading = payload; },
  setIsAddingInvestor: (state, payload) => { state.isAddingInvestor = payload; },
  setPartnerInvestorTable: (state, payload) => { state.partnerInvestorTable = payload; },
  setPartnerInvestorTableRow: (state, payload) => {
    const row = state.partnerInvestorTable.findIndex((el) => el.id == payload.id);
    for (const [key, value] of Object.entries(payload)) {
      state.partnerInvestorTable[row][key] = value;
    }
  },
  setPartnerInvestmentsTable: (state, payload) => { state.partnerInvestmentsTable = payload; },
  setIsPartnerInvestmentsTableLoading: (state, payload) => { state.isPartnerInvestmentsTableLoading = payload; },
  setIsFundTableLoading: (state, payload) => { state.isFundTableLoading = payload; },
  setFundTable: (state, payload) => { state.fundTable = payload; },
  setFundTableRow: (state, payload) => {
    const row = state.fundTable.findIndex((el) => el.id == payload.id);
    for (const [key, value] of Object.entries(payload)) {
      state.fundTable[row][key] = value;
    }
  },
  setIsPartnerInvestorOverviewLoading: (state, payload) => { state.isPartnerInvestorOverviewLoading = payload; },
  setPartnerInvestorOverview: (state, payload) => { state.partnerInvestorOverview = payload; },
  setAllInvestmentsLoading: (state, loading) => { state.allInvestmentsLoading = loading; },
  setPartnerInvestorAllInvestments: (state, payload) => { state.partnerInvestorAllInvestments = payload; },
  setIsRelatedManagersLoading: (state, payload) => { state.isRelatedManagersLoading = payload; },
  setRelatedManagers: (state, payload) => { state.partnerRelatedManagers = payload; },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
