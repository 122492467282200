<template>
  <nav id="navbar" fixed="up" variant="faded" type="dark" class="navbar py-3 d-lg-none">
    <div class="container main-nav">
      <div>
        <router-link :to="{ name: 'Dashboard' }">
          <img
            v-animate-logo
            src="@/ort-lib/assets/images/CORE_LOGO_color.png"
            alt="logo-core"
            width="30"
            height="30"
          />
        </router-link>
      </div>
      <div class="d-md-block d-lg-none">
        <!-- <ORTDropdown class="pages-btn"
           optionsHeight="300px"
           :options="showOptions"
           @input="chooseOption"
           :value="chosenOption"
        >
          <template #dropdownButton>
              <i class="fas fa-solid fa-bars fa-2x" style="color: #ef6861;"></i>
          </template>
        </ORTDropdown> -->
        <div class="pages-btn" @click="$emit('openSideBar')">
          <i class="fas fa-solid fa-bars fa-2x" style="color: #ef6861;"></i>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
/** @typedef {import("src/ort-lib/stores/accounts/jwt.js").Getters} JwtGetters */
/** @typedef {import("src/ort-lib/stores/accounts/jwt.js").Actions} JwtActions */

import { getAction, getGetter } from '@/utils/jsdoc.js';
import ORTDropdown from '@/ort-lib/components/ORTDropdown.vue';

export default {
  name: 'navbar',
  components: {
    ORTDropdown,
  },
  data() {
    return {
      showDropdown: false,
      chosenOption: '',
      options: [
        {
          label: 'Dashboard',
          value: 'dashboard',
        },
        {
          label: 'Finances',
          value: 'finances',
        },
        {
          label: 'Valuations',
          value: 'valuations',
        },
        {
          label: 'Users',
          value: 'users',
        },
        {
          label: 'Investors',
          value: 'investors',
        },
        {
          label: 'Partners',
          value: 'partners',
        },
        {
          label: 'Startups',
          value: 'startups',
        },
        {
          label: 'Fundraisings',
          value: 'fundraisings',
        },
        {
          label: 'Investments',
          value: 'investments',
        },
        {
          label: 'Sign out',
          value: 'sign_out',
        },
        {
          label: 'Settings',
          value: 'settings',
        },
      ],
      actions: {
        removeTokens: getAction(/** @type {JwtActions["removeTokens"]} */ ('removeTokens')),
      },
    };
  },
  methods: {
    logout() {
      this.actions.removeTokens();
    },
    chooseOption(value) {
      switch (value) {
        case 'dashboard':
          this.$router.push({ name: 'Dashboard' });
          break;
        case 'finances':
          this.$router.push({ name: 'Finances' });
          break;
        case 'investors':
          this.$router.push({ name: 'Investors' });
          break;
        case 'startups':
          this.$router.push({ name: 'Startups' });
          break;
        case 'fundraisings':
          this.$router.push({ name: 'Fundraisings' });
          break;
        case 'investments':
          this.$router.push({ name: 'Investments' });
          break;
        case 'partners':
          this.$router.push({ name: 'Partners' });
          break;
        case 'users':
          this.$router.push({ name: 'Users' });
          break;
        case 'valuations':
          this.$router.push({ name: 'Valuations' });
          break;
        case 'sign_out':
          this.logout();
          break;
        case 'settings':
          this.$router.push({ name: 'ProfileSettings' });
          break;
      }
    },
  },
  computed: {
    getters() {
      return {
        isOrtFinance: getGetter(/** @type {JwtGetters["isOrtFinance"]} */ ('isOrtFinance')),
      };
    },
    email() {
      return this.$store.state.email;
    },
    showOptions() {
      if (!this.getters.isOrtFinance) {
        return this.options.filter((option) => option.value !== 'finances');
      }
      return this.options;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/assets/css/global.scss';

@mixin linear-gradient($to, $primary, $secondary) {
  background: linear-gradient(to $to, $primary, $secondary 150%) !important;
  box-shadow: 0 5px 8px 0 rgba($purple-color, 0.5) !important;
  color: $white-color !important;
  border: none;
  transition: box-shadow 0.2s ease, background 0.2s ease !important;
  &:hover {
    background: linear-gradient(to $to, rgba($primary, 0.9), rgba($secondary, 0.9) 150%) !important;
    box-shadow: 0 3px 3px 0 rgba($purple-color, 0.5) !important;
  }
  &:active {
    background: linear-gradient(to $to, $primary, $secondary 150%) !important;
  }
  &:focus {
    background: linear-gradient(to $to, $primary, $secondary 150%) !important;
    &:hover {
      background: linear-gradient(
        to $to,
        rgba($primary, 0.9),
        rgba($secondary, 0.9) 150%
      ) !important;
      box-shadow: 0 3px 3px 0 rgba($purple-color, 0.5) !important;
    }
  }
}

#navbar {
  z-index: 2;
  height: 50px;
  position: fixed;
  padding-right: 20px;
  top: 0;
  box-shadow: 0 2px 5px 0 #dddddd;
  background-color: $white-color;
  width: 100vw;
  display: flex;
  align-content: center;
  @media (max-width: 768px) {
    .main-nav {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.pages-btn {
  cursor: pointer;
  padding-top: 10px;

  :deep() .ort-dropdown {
    border: none;
    box-shadow: none;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: flex-end;

  }
  :deep() .ort-dropdown:focus {
    box-shadow: none;
  }
  #user-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.navbar-section {
  font: $main-font-semibold;
  font-size: 14px;
  color: $red-color;
}

:deep() {
  .btn-secondary {
    background-color: $red-color !important;
    &:hover,
    &:focus,
    &:active {
      background-color: $red-darker-color !important;
    }
    @include linear-gradient(right, $red-color, $purple-color);
  }
}
</style>
