<template>
  <div class="div-text-area">
    <div class="field-header">
      <div class="d-flex icon-hover-wrapper">
        <div v-if="label !== null" :class="'label label-' + label_size">
          <span class="title">{{ label }}</span>
        </div>
      </div>
    </div>
    <div class="value" v-if="!this.editable">
      <span
        :class="
          !see_more && !isEmpty(showingValue) && showingValue.length > 275
            ? 'field-data'
            : 'field-data-full'
        "
        >{{ showingValue }}</span
      >
      <button
        v-if="!isEmpty(showingValue) && showingValue.length > 275"
        @mousedown="seeMore"
        class="see-more-button"
      >
        <p v-show="!see_more">See more</p>
        <p v-show="see_more">See less</p>
      </button>
    </div>
    <textarea
      v-if="this.editable"
      class="input"
      :value="this.value"
      :placeholder="placeholder"
      :rows="rows"
      :max-rows="maxRows"
      @input="updateValue($event.target.value)"
      v-bind:class="{
        'input-valid': state === 'valid',
        'input-pre-invalid': state === 'pre-invalid',
        'input-post-invalid': state === 'post-invalid'
      }"
    >
    </textarea>
    <span
      v-if="state === 'pre-invalid' || state === 'post-invalid'"
      class="feedback"
      v-bind:class="{
        'feedback-pre-invalid': state === 'pre-invalid',
        'feedback-post-invalid': state === 'post-invalid'
      }"
      >{{ feedback }}</span
    >
  </div>
</template>

<script>
export default {
  name: 'TextArea',
  props: {
    state: {
      type: String,
      default: 'null',
    },
    feedback: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: null,
    },
    label_size: {
      type: String,
      default: 'large',
    },
    rows: {
      type: Number,
      default: 3,
    },
    field: {
      type: String,
      default: '',
    },
    parent: {
      type: String,
      default: null,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    initialValue: {
      default: '',
    },
    'max-rows': {
      type: Number,
      default: 6,
    },
    required: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      showingValue: this.initialValue,
      value: this.initialValue,
      see_more: false,
    };
  },
  watch: {
    state(newVal) {
      this.state = newVal;
    },
    feedback(newVal) {
      this.feedback = newVal;
    },
    initialValue(new_val) {
      this.value = new_val;
    },
  },
  methods: {
    updateValue(value) {
      this.value = value;
      this.$emit('input', this.value, this.field, this.required, !!this.value);
    },
    openField() {
      this.edit = true;
    },
    saveField() {
      this.edit = false;
      this.showingValue = this.value;
      this.$emit('save', this.value, this.field, this.parent);
    },
    closeField() {
      this.edit = false;
    },
    seeMore() {
      this.see_more = !this.see_more;
    },
  },
  created() {
    this.value = this.initialValue;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

.div-text-area {
  width: 100%;
  .field-header {
    margin-bottom: 10px;
    .icon-hover-wrapper {
      width: fit-content;
      &:hover .icon-info {
        visibility: visible !important;
        opacity: 1 !important;
      }
      .label {
        text-align: left;
        font: $main-font-semibold;
        color: $black-color;
        &-medium {
          font-size: $medium-font-size;
        }
        &-large {
          font-size: $slarge-font-size;
        }
      }
      @media (max-width: 767px) {
        .label {
          font-size: 15px;
        }
      }
      .edit-icons {
        display: flex;
        align-items: center;
        &-image {
          justify-content: center;
        }
        .icon-link {
          margin-left: 5px;
          height: 23px;
          width: 23px;
          color: $red-color;
          border-radius: 100%;
          padding: 5px;
          transition: color 0.3s ease, background 0.3s ease;
          &:hover,
          &:focus,
          &:active {
            background-color: rgba($red-lighter-color, 0.6);
          }
        }
        .icon-info {
          margin-left: 5px;
          height: 15px;
          width: 15px;
          color: $black-color;
          visibility: hidden;
          opacity: 0;
          transition: all 0.3s ease;
        }
        .dual-icons {
          display: flex;
          flex-flow: row;
        }
      }
    }
  }
  .input {
    width: 100%;
    padding: 15px;
    width: 100%;
    border-radius: 12px;
    min-height: 190px;
    border: solid 1px $grey-color;
    font-size: $medium-font-size;
    background-color: $white-color;
    outline: none;
    transition: box-shadow 0.3s ease, border 0.3s ease, color 0.3s ease;
    &:hover,
    &:focus,
    &:active {
      color: $black-color;
      box-shadow: none;
    }
    &-valid {
      border: solid 1px $green-darker-color !important;
    }
    &-pre-invalid {
      border: solid 1px $yellow-color !important;
    }
    &-post-invalid {
      border: solid 1px $red-color !important;
    }
  }
  .feedback {
    &-pre-invalid {
      color: $yellow-color;
    }
    &-post-invalid {
      color: $red-color;
    }
  }
}

.title {
  font-size: 0.875rem;
  font-family: $main-font-family;
  line-height: 24px;
}

.see-more-button {
  background-color: white;
  color: black;
  border: solid 0.5px lightgrey;
  margin-top: 5px;
  border-radius: 10px;
  padding: 5px;
  font-size: 13px;
}
</style>
