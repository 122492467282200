import moment from 'moment';
import { axiosCore } from '@/plugins/axios';
import { setAmount, getPercentageChange } from '@/utils/formatters.js';

const state = {
  globalYearKpi: {
    total_amount_raised: 0,
    amount_raised_fund: 0,
    amount_raised_corporate: 0,
    amount_raised_clubdeal: 0,
    percentage_total: 0,
    percentage_fund: 0,
    percentage_corporate: 0,
    percentage_clubdeal: 0,
  },
  globalMonthKpi: {
    total_amount_raised: 0,
    amount_raised_fund: 0,
    amount_raised_corporate: 0,
    amount_raised_clubdeal: 0,
    percentage_total: 0,
    percentage_fund: 0,
    percentage_corporate: 0,
    percentage_clubdeal: 0,
  },
  globalWeekKpi: {
    total_amount_raised: 0,
    amount_raised_fund: 0,
    amount_raised_corporate: 0,
    amount_raised_clubdeal: 0,
    percentage_total: 0,
    percentage_fund: 0,
    percentage_corporate: 0,
    percentage_clubdeal: 0,
  },
  globalTotalKpi: {
    total_amount_raised: 0,
    amount_raised_fund: 0,
    amount_raised_corporate: 0,
    amount_raised_clubdeal: 0,
    percentage_total: 0,
    percentage_fund: 0,
    percentage_corporate: 0,
    percentage_clubdeal: 0,
  },
};

const getters = {
  kpiThisYear: (state) => ({
    globalQuantity: setAmount(state.globalYearKpi.total_amount_raised, 'EUR', false),
    globalPercentage: state.globalYearKpi.percentage_total,

    rhapsodyQuantity: setAmount(state.globalYearKpi.amount_raised_fund, 'EUR', false),
    rhapsodyPercentage: state.globalYearKpi.percentage_fund,

    corporateQuantity: setAmount(state.globalYearKpi.amount_raised_corporate, 'EUR', false),
    corporatePercentage: state.globalYearKpi.percentage_corporate,

    clubQuantity: setAmount(state.globalYearKpi.amount_raised_clubdeal, 'EUR', false),
    clubPercentage: state.globalYearKpi.percentage_clubdeal,
  }),
  kpiTotal: (state) => ({
    globalQuantity: setAmount(state.globalTotalKpi.total_amount_raised, 'EUR', false),
    globalPercentage: state.globalTotalKpi.percentage_total,

    rhapsodyQuantity: setAmount(state.globalTotalKpi.amount_raised_fund, 'EUR', false),
    rhapsodyPercentage: state.globalTotalKpi.percentage_fund,

    corporateQuantity: setAmount(state.globalTotalKpi.amount_raised_corporate, 'EUR', false),
    corporatePercentage: state.globalTotalKpi.percentage_corporate,

    clubQuantity: setAmount(state.globalTotalKpi.amount_raised_clubdeal, 'EUR', false),
    clubPercentage: state.globalTotalKpi.percentage_clubdeal,
  }),
  kpiThisMonth: (state) => ({
    globalQuantity: setAmount(state.globalMonthKpi.total_amount_raised, 'EUR', false),
    globalPercentage: state.globalMonthKpi.percentage_total,

    rhapsodyQuantity: setAmount(state.globalMonthKpi.amount_raised_fund, 'EUR', false),
    rhapsodyPercentage: state.globalMonthKpi.percentage_fund,

    corporateQuantity: setAmount(state.globalMonthKpi.amount_raised_corporate, 'EUR', false),
    corporatePercentage: state.globalMonthKpi.percentage_corporate,

    clubQuantity: setAmount(state.globalMonthKpi.amount_raised_clubdeal, 'EUR', false),
    clubPercentage: state.globalMonthKpi.percentage_clubdeal,
  }),
  kpiThisWeek: (state) => ({
    globalQuantity: setAmount(state.globalWeekKpi.total_amount_raised, 'EUR', false),
    globalPercentage: state.globalWeekKpi.percentage_total,

    rhapsodyQuantity: setAmount(state.globalWeekKpi.amount_raised_fund, 'EUR', false),
    rhapsodyPercentage: state.globalWeekKpi.percentage_fund,

    corporateQuantity: setAmount(state.globalWeekKpi.amount_raised_corporate, 'EUR', false),
    corporatePercentage: state.globalWeekKpi.percentage_corporate,

    clubQuantity: setAmount(state.globalWeekKpi.amount_raised_clubdeal, 'EUR', false),
    clubPercentage: state.globalWeekKpi.percentage_clubdeal,
  }),
};

const actions = {
  getGlobalKpi({ dispatch }) {
    dispatch('requestWeeklyGlobalKpi');
    dispatch('requestMonthlyGlobalKpi');
    dispatch('requestYearlyGlobalKpi');
    dispatch('requestTotalGlobalKpi');
  },

  requestWeeklyGlobalKpi({ commit }) {
    const date = new Date();
    const today = moment(date).format('YYYY-MM-DD');

    const getMonday = function (d) {
      d = new Date(d);
      const day = d.getDay();
      const diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
      return new Date(d.setDate(diff));
    };
    // Week
    const thisWeek = () => {
      const startDate = getMonday(date);
      return `?date_after=${moment(startDate).format('YYYY-MM-DD')}&date_before=${today}`;
    };
    const lastWeek = () => {
      const aWeekAgoDate = new Date();
      aWeekAgoDate.setDate(aWeekAgoDate.getDate() - 7);
      const startDate = getMonday(aWeekAgoDate);
      const endDate = new Date(startDate);
      return `?date_after=${moment(startDate).format('YYYY-MM-DD')}&date_before=${moment(
        endDate.setDate(endDate.getDate() + 6),
      ).format('YYYY-MM-DD')}`;
    };
    // Week
    axiosCore.get(`/kpi/global_raised${thisWeek()}`).then(
      (response) => {
        const kpiData = {
          total_amount_raised: response.data.total_amount_raised,
          amount_raised_fund: response.data.amount_raised_fund,
          amount_raised_corporate: response.data.amount_raised_corporate,
          amount_raised_clubdeal: response.data.amount_raised_clubdeal,
        };
        commit('setWeeklyGlobalKpi', kpiData);
        axiosCore.get(`/kpi/global_raised${lastWeek()}`).then(
          (response) => {
            // Get the percentage of change per period
            const total_percentage_per_week = getPercentageChange(
              kpiData.total_amount_raised,
              response.data.total_amount_raised,
            );
            const rhapsody_percentage_per_week = getPercentageChange(
              kpiData.amount_raised_fund,
              response.data.amount_raised_fund,
            );
            const corporate_percentage_per_week = getPercentageChange(
              kpiData.amount_raised_corporate,
              response.data.amount_raised_corporate,
            );
            const clubdeal_percentage_per_week = getPercentageChange(
              kpiData.amount_raised_clubdeal,
              response.data.amount_raised_clubdeal,
            );

            const percentageData = {
              percentage_total: total_percentage_per_week,
              percentage_fund: rhapsody_percentage_per_week,
              percentage_corporate: corporate_percentage_per_week,
              percentage_clubdeal: clubdeal_percentage_per_week,
            };
            commit('setWeeklyGlobalKpi', percentageData);
          },
          () => {},
        );
      },
      () => {},
    );
  },

  requestMonthlyGlobalKpi({ commit }) {
    const date = new Date();
    const today = moment(date).format('YYYY-MM-DD');

    const daysInMonth = function (month, year) {
      return new Date(year, month, 0).getDate();
    };
    const currentMonth = date.getMonth();
    const currentYear = date.getFullYear();

    // Month
    const thisMonth = () => {
      const startDate = moment(new Date(currentYear, currentMonth, 1)).format('YYYY-MM-DD');
      return `?date_after=${startDate}&date_before=${today}`;
    };
    const lastMonth = () => {
      let previousMonth;
      let year = currentYear;
      if (currentMonth == 0) {
        previousMonth = 11;
        year -= 1;
      } else {
        previousMonth = currentMonth - 1;
      }
      const lastDayOfLastMonth = daysInMonth(previousMonth + 1, year);
      const startDate = moment(new Date(year, previousMonth, 1)).format('YYYY-MM-DD');
      const endDate = moment(new Date(year, previousMonth, lastDayOfLastMonth)).format('YYYY-MM-DD');
      return `?date_after=${startDate}&date_before=${endDate}`;
    };
    // Month
    axiosCore.get(`/kpi/global_raised${thisMonth()}`).then(
      (response) => {
        const kpiData = {
          total_amount_raised: response.data.total_amount_raised,
          amount_raised_fund: response.data.amount_raised_fund,
          amount_raised_corporate: response.data.amount_raised_corporate,
          amount_raised_clubdeal: response.data.amount_raised_clubdeal,
        };
        commit('setMonthlyGlobalKpi', kpiData);
        axiosCore.get(`/kpi/global_raised${lastMonth()}`).then(
          (response) => {
            // Get the percentage of change per period
            const total_percentage_per_month = getPercentageChange(
              kpiData.total_amount_raised,
              response.data.total_amount_raised,
            );
            const rhapsody_percentage_per_month = getPercentageChange(
              kpiData.amount_raised_fund,
              response.data.amount_raised_fund,
            );
            const corporate_percentage_per_month = getPercentageChange(
              kpiData.amount_raised_corporate,
              response.data.amount_raised_corporate,
            );
            const clubdeal_percentage_per_month = getPercentageChange(
              kpiData.amount_raised_clubdeal,
              response.data.amount_raised_clubdeal,
            );

            const percentageData = {
              percentage_total: total_percentage_per_month,
              percentage_fund: rhapsody_percentage_per_month,
              percentage_corporate: corporate_percentage_per_month,
              percentage_clubdeal: clubdeal_percentage_per_month,
            };
            commit('setMonthlyGlobalKpi', percentageData);
          },
          () => {},
        );
      },
      () => {},
    );
  },

  requestYearlyGlobalKpi({ commit }) {
    const date = new Date();
    const today = moment(date).format('YYYY-MM-DD');

    // Year
    const thisYear = () => {
      const startDate = moment(new Date(date.getFullYear(), 0, 1)).format('YYYY-MM-DD');
      return `?date_after=${startDate}&date_before=${today}`;
    };
    const lastYear = () => {
      const startDate = moment(new Date(date.getFullYear() - 1, 0, 1)).format('YYYY-MM-DD');
      const endDate = moment(new Date(date.getFullYear() - 1, 11, 31)).format('YYYY-MM-DD');
      return `?date_after=${startDate}&date_before=${endDate}`;
    };

    // Year
    axiosCore.get(`/kpi/global_raised${thisYear()}`).then(
      (response) => {
        const kpiData = {
          total_amount_raised: response.data.total_amount_raised,
          amount_raised_fund: response.data.amount_raised_fund,
          amount_raised_corporate: response.data.amount_raised_corporate,
          amount_raised_clubdeal: response.data.amount_raised_clubdeal,
        };
        commit('setYearlyGlobalKpi', kpiData);
        axiosCore.get(`/kpi/global_raised${lastYear()}`).then(
          (response) => {
            // Get the percentage of change per period
            const total_percentage_per_year = getPercentageChange(
              kpiData.total_amount_raised,
              response.data.total_amount_raised,
            );
            const rhapsody_percentage_per_year = getPercentageChange(
              kpiData.amount_raised_fund,
              response.data.amount_raised_fund,
            );
            const corporate_percentage_per_year = getPercentageChange(
              kpiData.amount_raised_corporate,
              response.data.amount_raised_corporate,
            );
            const clubdeal_percentage_per_year = getPercentageChange(
              kpiData.amount_raised_clubdeal,
              response.data.amount_raised_clubdeal,
            );

            const percentageData = {
              percentage_total: total_percentage_per_year,
              percentage_fund: rhapsody_percentage_per_year,
              percentage_corporate: corporate_percentage_per_year,
              percentage_clubdeal: clubdeal_percentage_per_year,
            };
            commit('setYearlyGlobalKpi', percentageData);
          },
          () => {},
        );
      },
      () => {},
    );
  },

  // Total
  requestTotalGlobalKpi({ commit }) {
    const date = new Date();
    const today = moment(date).format('YYYY-MM-DD');
    const firstYear = '?date_after=' + '2017-01-01';

    // Year
    const thisYear = () => {
      const startDate = moment(new Date(date.getFullYear(), 0, 1)).format('YYYY-MM-DD');
      return `?date_after=${startDate}&date_before=${today}`;
    };

    // Year
    axiosCore.get(`/kpi/global_raised${firstYear}`).then(
      (response) => {
        const kpiData = {
          total_amount_raised: response.data.total_amount_raised,
          amount_raised_fund: response.data.amount_raised_fund,
          amount_raised_corporate: response.data.amount_raised_corporate,
          amount_raised_clubdeal: response.data.amount_raised_clubdeal,
        };
        commit('setTotalGlobalKpi', kpiData);
        axiosCore.get(`/kpi/global_raised${thisYear()}`).then(
          (response) => {
            // Get the percentage of change per period
            const total_percentage_since_beginning = getPercentageChange(
              kpiData.total_amount_raised,
              response.data.total_amount_raised,
            );
            const rhapsody_percentage_since_beginning = getPercentageChange(
              kpiData.amount_raised_fund,
              response.data.amount_raised_fund,
            );
            const corporate_percentage_since_beginning = getPercentageChange(
              kpiData.amount_raised_corporate,
              response.data.amount_raised_corporate,
            );
            const clubdeal_percentage_since_beginning = getPercentageChange(
              kpiData.amount_raised_clubdeal,
              response.data.amount_raised_clubdeal,
            );

            const percentageData = {
              percentage_total: total_percentage_since_beginning,
              percentage_fund: rhapsody_percentage_since_beginning,
              percentage_corporate: corporate_percentage_since_beginning,
              percentage_clubdeal: clubdeal_percentage_since_beginning,
            };
            commit('setTotalGlobalKpi', percentageData);
          },
          () => {},
        );
      },
      () => {},
    );
  },
};

const mutations = {
  setWeeklyGlobalKpi: (state, kpiData) => (state.globalWeekKpi = { ...state.globalWeekKpi, ...kpiData }),
  setMonthlyGlobalKpi: (state, kpiData) => (state.globalMonthKpi = { ...state.globalMonthKpi, ...kpiData }),
  setYearlyGlobalKpi: (state, kpiData) => (state.globalYearKpi = { ...state.globalYearKpi, ...kpiData }),
  setTotalGlobalKpi: (state, kpiData) => (state.globalTotalKpi = { ...state.globalTotalKpi, ...kpiData }),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
