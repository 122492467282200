<template>
  <img
    :src="this.image_src"
    :class="`img-fluid ${this.type}`"
    :width="width"
    :height="height"
    @error="imageLoadError"
  />
</template>

<script>
export default {
  name: 'Picture',
  props: {
    src: {
      type: String,
      required: false,
    },
    'src-blank': {
      type: String,
      required: false,
    },
    width: {
      type: String,
      required: false,
    },
    height: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      image_src: null,
    };
  },
  computed: {},
  methods: {
    clicked() {
      this.$emit('click');
    },
    reachImage() {
      this.image_src = this.src;
      if (this.isEmpty(this.image_src) && !this.isEmpty(this.srcBlank)) {
        this.image_src = this.srcBlank;
      }
    },
    imageLoadError() {
      if (!this.isEmpty(this.srcBlank)) {
        this.image_src = this.srcBlank;
      }
    },
  },
  created() {
    this.reachImage();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

.profile-picture {
  border: 1px solid $grey-color;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  cursor: pointer;
  object-fit: cover;
}

.profile-picture-nav {
  border: 1px solid $grey-color;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  cursor: pointer;
  object-fit: cover;
}

.profile-picture-small {
  border: 1px solid $grey-color;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  object-fit: contain;
  background-color: $white-color;
}
</style>
