const state = {
  showBillUkModal: false,
  showBillFRModal: false,
  showSendEmailModal: false,
  showCashCallModal: false,
  showMultipleCashCallModal: false,
  dataBillFRModal: {},
  dataSendEmailModal: {},
  dataCashCallModal: {},
  dataMultipleCashCallModal: [],
};

const getters = {
  getShowBillUkModal: (state) => state.showBillUkModal,
  getShowBillFRModal: (state) => state.showBillFRModal,
  getShowSendEmailModal: (state) => state.showSendEmailModal,
  getShowCashCallModal: (state) => state.showCashCallModal,
  getShowMultipleCashCallModal: (state) => state.showMultipleCashCallModal,
  getDataBillFRModal: (state) => state.dataBillFRModal,
  getDataSendEmailModal: (state) => state.dataSendEmailModal,
  getDataCashCallModal: (state) => state.dataCashCallModal,
  getDataMultipleCashCallModal: (state) => state.dataMultipleCashCallModal,
};

const actions = {};

const mutations = {
  setShowBillUkModal: (state, show) => (state.showBillUkModal = show),
  setShowBillFRModal: (state, show) => (state.showBillFRModal = show),
  setShowSendEmailModal: (state, show) => (state.showSendEmailModal = show),
  setShowCashCallModal: (state, show) => (state.showCashCallModal = show),
  setShowMultipleCashCallModal: (state, show) => (state.showMultipleCashCallModal = show),
  setDataBillFRModal: (state, data) => (state.dataBillFRModal = data),
  setDataSendEmailModal: (state, data) => (state.dataSendEmailModal = data),
  setDataCashCallModal: (state, data) => (state.dataCashCallModal = data),
  setDataMultipleCashCallModal: (state, data) => (state.dataMultipleCashCallModal = data),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
