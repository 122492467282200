import { axiosCore } from '@/plugins/axios';

const state = {};

const getters = {};

const actions = {
  async getUser({ commit }, owner) {
    return new Promise((resolve, reject) => {
      axiosCore.get(`/auth/users/${owner}/contact-info`).then((response) => {
        resolve(response.data);
      });
    });
  },
  getTeamInfo({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      const url = `/${data.entityType}s/${data.entityId}/team-info`;
      axiosCore.get(url).then(async (response) => {
        const team = response.data;
        const promisesUnresolved = team.map(async (team_member) => ({
          ...team_member,
          user: await dispatch('getUser', team_member?.owner),
        }));
        const teamComputed = await Promise.all(promisesUnresolved);
        resolve(teamComputed);
      });
    });
  },
  getTeamSelects() {
    return new Promise((resolve, reject) => {
      const selects = {};
      axiosCore.get('choices/?choices=user_entity_relationship_status_choices').then((response) => {
        selects.status_options = response.data;
      }),
      axiosCore
        .get('choices/?choices=user_investor_relationship_type_choices')
        .then((response) => {
          selects.type_options = response.data;
        });
      resolve(selects);
    });
  },
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
