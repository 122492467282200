import { axiosCore } from '@/plugins/axios';
import { errorAlert, successAlert } from '@/ort-lib/utils/utils';

const state = {
  experiences: [],
  totalExperiences: 0,
  isLoading: false,
};

const getters = {
    getExperiences: (state) => state.experiences,
    getTotalExperiences: (state) => state.totalExperiences,
    getIsExperiencesTableLoading: (state) => state.isLoading,
  };

const mutations = {
    setExperiences: (state, experiences) => (state.experiences = experiences),
    setTotalExperiences: (state, totalExperiences) => (state.totalExperiences = totalExperiences),
    setIsLoading: (state, isLoading) => (state.isLoading = isLoading),
};

const actions = {
    async fetchBaExperiences ({ commit }, {
      perPage, page, searchParam, selectedFilters, order,
    }) {
      try {
        const response = await axiosCore.get(`/business-angels/linkedin-experiences/?${searchParam}page=${page}&page_size=${perPage}${selectedFilters}${order}`);
        commit('setExperiences', response.data.results);
        commit('setTotalExperiences', response.data.count);
      } catch (error) {
        errorAlert("Could not fetch Business Angels", error.message)
      }
    },
  };

export default {
    state,
    getters,
    mutations,
    actions,
};