<template>
  <span class="tag" :title="tagId">
    <span class="tag-label">{{ label.name }}</span>
  </span>
</template>

<script>
export default {
  name: 'TagLabel',
  props: {
    'tag-id': {
      required: true,
    },
    text: {
      type: String,
      required: false,
      default: '',
    },
    select: {
      type: Array,
      required: false,
    },
  },
  watch: {
    tagId(newVal) {
      this.getLabel(this.select);
    },
    select: {
      handler(newVal) {
        this.getLabel(newVal);
      },
      deep: true,
    },
  },
  data() {
    return {
      label: {
        name: '',
      },
    };
  },
  methods: {
    getLabel(select) {
      if (this.text !== '') {
        this.label.name = this.text;
      } else if (this.isEmpty(select)) {
        this.label = this.tagId;
      } else {
        for (const option in select) {
          if (select[option].id === this.tagId || select[option].id === this.tagId.id) {
            this.label = select[option];
            break;
          }
        }
      }
      this.loading = false;
    },
  },
  created() {
    this.getLabel(this.select);
  },
  updated() {
    this.getLabel(this.select);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

.tag {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  line-height: 1;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  padding: 4px 11px;
  height: 25px;
  border-radius: 12.5px;
  box-shadow: 0 5px 8px 0 rgba(139, 216, 244, 0.2);
  background-color: #e7e1f4;
  .tag-label {
    font-size: 12px;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: -0.23px;
    text-align: center;
    color: #8471b0;
  }
}
</style>
