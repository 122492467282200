<template>
  <img :src="this.image_src" class="profile-picture" @error="imageLoadError" />
</template>

<script>
export default {
  name: 'NewPicture',
  props: {
    src: {
      type: String,
      required: false,
    },
    'src-blank': {
      type: String,
      required: false,
    },
    fluid: {
      type: Boolean,
      required: false,
      default: true,
    },
    width: {
      type: String,
      required: false,
    },
    height: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      image_src: null,
    };
  },
  computed: {},
  methods: {
    clicked() {
      this.$emit('click');
    },
    reachImage() {
      this.image_src = this.src;
      if (this.isEmpty(this.image_src) && !this.isEmpty(this.srcBlank)) {
        this.image_src = this.srcBlank;
      }
    },
    imageLoadError() {
      if (!this.isEmpty(this.srcBlank)) {
        this.image_src = this.srcBlank;
      }
    },
  },
  created() {
    this.reachImage();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

.profile-picture {
  box-shadow: 0 2px 6px 0 #dddddd;
  border: solid 4px #ffffff;
  background-color: #ffffff;
  width: 120px;
  height: 120px;
  margin-top: -40%;
  border-radius: 50%;
  cursor: pointer;
  object-fit: cover;
}
@media (max-width: 767px) {
  .profile-card {
    margin-top: 50px;
  }
}

.profile-picture-nav {
  border: 1px solid $grey-color;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  cursor: pointer;
  object-fit: cover;
}

.profile-picture-small {
  border: 1px solid $grey-color;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  object-fit: contain;
  background-color: $white-color;
}
</style>
