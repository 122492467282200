import { axiosCore } from '@/plugins/axios';

// All related to getting choices or options is going to be included here
// Eg Currencies, status, stages, types, etc..

async function conditionalPromise(commit, getters, apiData, mutation, getter) {
  if (getters[getter].length === 0) {
    await makePromise(commit, apiData, mutation);
  }
  return new Promise((resolve) => {
    resolve(getters[getter]);
  });
}

function makePromise(commit, apiData, mutation) {
  return new Promise((resolve, reject) => {
    apiData.axiosBase.get(apiData.url).then(
      (response) => {
        commit(mutation, response.data);
        resolve(response);
      },
      (error) => {
        reject(error);
      },
    );
  });
}

const state = {
  currencies: [],
  countries: [],
  cities: [],
  notificationChoices: [],
  languageChoices: [],
  investorTypeChoices: [],
  investorInvestmentOffer: [],
  fundDistributedChoices: [],
  contractTypeChoices: [],
  partnerResponsibleChoices: [],
  relationshipStatusChoices: [],
  kycIncomingRangeChoices: [],
};

const getters = {
  yesNoOptions: () => [
    { value: false, label: 'No' },
    { value: true, label: 'Yes' },
  ],
  currencies: (state) => state.currencies,
  countries: (state) => state.countries,
  cities: (state) => state.cities,
  notificationChoices: (state) => state.notificationChoices,
  languageChoices: (state) => state.languageChoices,
  investorTypeChoices: (state) => state.investorTypeChoices,
  investorInvestmentOffer: (state) => state.investorInvestmentOffer,
  fundDistributedChoices: (state) => state.fundDistributedChoices,
  fundDistributedChoicesNames: (state) => state.fundDistributedChoices.map((item) => item.name),
  contractTypeChoices: (state) => state.contractTypeChoices,
  partnerResponsibleChoices: (state) => state.partnerResponsibleChoices,
  relationshipStatusChoices: (state) => state.relationshipStatusChoices,
  investorRelationshipTypeChoices: (state) => state.investorRelationshipTypeChoices,
  kycIncomingRangeChoices: (state) => state.kycIncomingRangeChoices,
};

const actions = {
  getCurrencies({ commit, getters }) {
    const data = {
      url: 'currencies/',
      axiosBase: axiosCore,
    };
    const mutation = 'setCurrencies';
    return conditionalPromise(commit, getters, data, mutation, 'currencies');
  },
  getCountries({ commit, getters }) {
    const data = {
      url: 'countries/',
      axiosBase: axiosCore,
    };
    const mutation = 'setCountries';
    return conditionalPromise(commit, getters, data, mutation, 'countries');
  },
  getCities({ commit }, searchQuery) {
    const data = {
      url: `/cities?query=${searchQuery}`,
      axiosBase: axiosCore,
    };
    const mutation = 'setCities';
    return makePromise(commit, data, mutation);
  },
  getInvestorTypeChoices({ commit, getters }) {
    const data = {
      url: 'choices/?choices=InvestorTypeChoices',
      axiosBase: axiosCore,
    };
    const mutation = 'setInvestorTypeChoices';
    const state = 'investorTypeChoices';
    return conditionalPromise(commit, getters, data, mutation, state);
  },
  getInvestorInvestmentOfferChoices({ commit, getters }) {
    const data = {
      url: 'choices/?choices=KYCInvestmentManagement',
      axiosBase: axiosCore,
    };
    const mutation = 'setInvestorInvestmentOffer';
    const state = 'investorInvestmentOffer';
    return conditionalPromise(commit, getters, data, mutation, state);
  },
  getFundDistributedChoices({ commit, getters }) {
    const data = {
      url: 'startups/fund-list',
      axiosBase: axiosCore,
    };
    const mutation = 'setFundDistributedChoices';
    const state = 'fundDistributedChoices';
    return conditionalPromise(commit, getters, data, mutation, state);
  },
  getContractTypeChoices({ commit, getters }) {
    const data = {
      url: 'choices/?choices=contract_type_choices',
      axiosBase: axiosCore,
    };
    const mutation = 'setContractTypeChoices';
    const state = 'contractTypeChoices';
    return conditionalPromise(commit, getters, data, mutation, state);
  },
  getPartnerResponsibleChoices({ commit, getters }) {
    const data = {
      url: 'choices/?choices=investor_partner_responsible_choices',
      axiosBase: axiosCore,
    };
    const mutation = 'setPartnerResponsibleChoices';
    const state = 'partnerResponsibleChoices';
    return conditionalPromise(commit, getters, data, mutation, state);
  },
  getRelationshipStatusChoices({ commit, getters }) {
    const data = {
      url: 'choices/?choices=user_entity_relationship_status_choices',
      axiosBase: axiosCore,
    };
    const mutation = 'setRelationshipStatusChoices';
    const state = 'relationshipStatusChoices';
    return conditionalPromise(commit, getters, data, mutation, state);
  },
  getKYCIncomingRangeChoices({ commit, getters }) {
    const data = {
      url: 'choices/?choices=kyc_incoming_range_choices',
      axiosBase: axiosCore,
    };
    const mutation = 'setKYCIncomingRangeChoices';
    const state = 'kycIncomingRangeChoices';
    return conditionalPromise(commit, getters, data, mutation, state);
  },
};

const mutations = {
  setCurrencies: (state, currencies) => (state.currencies = currencies),
  setCountries: (state, countries) => (
    countries.forEach((country) => {
      state.countries.push({ value: country.id, text: country.name });
    })
  ),
  setCities: (state, cities) => (state.cities = { ...state.cities, ...cities }),
  setNotificationChoices: (state, types) => (state.notificationChoices = types),
  setLanguageChoices: (state, types) => (state.languageChoices = types),
  setInvestorTypeChoices: (state, choices) => (state.investorTypeChoices = choices),
  setInvestorInvestmentOffer: (state, choices) => (state.investorInvestmentOffer = choices),
  setFundDistributedChoices: (state, funds) => {
    funds.forEach((fund) => {
      state.fundDistributedChoices.push({ value: fund.id, name: fund.name });
    });
    state.fundDistributedChoices.push({ value: 'club_deal', name: 'Club Deal' });
  },
  setContractTypeChoices: (state, choices) => (state.contractTypeChoices = choices),
  setPartnerResponsibleChoices: (state, choices) => (state.partnerResponsibleChoices = choices),
  setRelationshipStatusChoices: (state, choices) => (state.relationshipStatusChoices = choices),
  setKYCIncomingRangeChoices: (state, choices) => (state.kycIncomingRangeChoices = choices),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
