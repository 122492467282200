import { axiosCore } from '@/plugins/axios';
import { errorAlert, successAlert } from '@/ort-lib/utils/utils';

const state = {
  profiles: [],
  totalProfiles: 0,
  isLoading: false,
};

const getters = {
  getProfiles: (state) => state.profiles,
  getTotalProfiles: (state) => state.totalProfiles,
  getIsProfilesTableLoading: (state) => state.isLoading,
};

const mutations = {
  setProfiles: (state, profiles) => (state.profiles = profiles),
  setTotalProfiles: (state, totalProfiles) => (state.totalProfiles = totalProfiles),
  setIsLoading: (state, isLoading) => (state.isLoading = isLoading),
};

const actions = {
  async fetchBaProfiles({ commit }, {
    perPage, page, searchParam,
  }) {
    try {
      const response = await axiosCore.get(`/business-angels/linkedin-profiles/?${searchParam}page=${page}&page_size=${perPage}`);
      commit('setProfiles', response.data.results);
      commit('setTotalProfiles', response.data.count);
    } catch (error) {
      errorAlert("Could not fetch Business Angels", error.message)
    }
  },
  async addBusinessAngel({ dispatch }, url) {
    try {
      await axiosCore.post('/business-angels/linkedin-profile/', { url });
      dispatch('fetchBaProfiles', { perPage: 10, page: 1, searchParam: '' });
      successAlert('Business Angel added successfully!');
    } catch (error) {
      errorAlert("Could not add Business Angel", error.message)
    }
  },
  async deleteBusinessAngel({ dispatch }, id) {
    try {
      await axiosCore.delete(`/business-angels/linkedin-profile/${id}/`);
      dispatch('fetchBaProfiles', { perPage: 10, page: 1, searchParam: '' });
      successAlert('Business Angel deleted successfully!');
    } catch (error) {
      errorAlert("Could not delete Business Angel", error.message);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};