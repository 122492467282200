import { axiosCore } from '@/plugins/axios';

const state = {
  allPartners: [],
  IsAllPartnersTableLoading: false,
  allPartnersCount: 0,
};

const getters = {
  getAllPartners: (state) => state.allPartners,
  getIsAllPartnersTableLoading: (state) => state.IsAllPartnersTableLoading,
  getAllPartnersCount: (state) => state.allPartnersCount,
};

const mutations = {
  setAllPartners: (state, data) => {
    state.allPartners = data;
  },
  setIsAllPartnersTableLoading: (state, payload) => {
    state.IsAllPartnersTableLoading = payload;
  },
  setAllPartnersCount: (state, payload) => {
    state.allPartnersCount = payload;
  },
};

const actions = {
  async fetchAllPartners({ commit, dispatch }, {
    perPage, page, searchParam, selectedFilters,
  }) {
    commit('setIsAllPartnersTableLoading', true);
    try {
      const response = await axiosCore.get(`/partners/list-core?${searchParam}page=${page}&page_size=${perPage}${selectedFilters}`);
      commit('setAllPartners', response.data?.results);
      commit('setAllPartnersCount', response.data?.count);
    } catch (error) {
      dispatch('triggerAlert', {
        show: true,
        type: 'danger',
        message: error.message,
      });
    } finally {
      commit('setIsAllPartnersTableLoading', false);
    }
  },
  async exportPartnersTable({ dispatch }, {
    perPage, page, searchParam, selectedFilters,
  }) {
    try {
      const response = await axiosCore.get(`/partners/list-core?${searchParam}page=${page}&page_size=${perPage}${selectedFilters}&format=csv`);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'export.csv');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      dispatch('triggerAlert', {
        show: true,
        type: 'danger',
        message: error.message,
      });
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
