import { axiosCore } from '@/plugins/axios';

const state = {
  docuSign: {
    message: '',
    status: null,
    documentId: null,
    documentTemplateName: '',
  },
  allInvestments: [],
  IsAllInvestmentsTableLoading: false,
  allInvestmentsCount: 0,
  InvestmentStatusChoices: [],
  cashcallMultipleResponse: {
    cashcalled: '',
    errors: [],
  },
};

const getters = {
  docuSign: (state) => state.docuSign,

  getAllInvestments: (state) => state.allInvestments,
  getIsAllInvestmentsTableLoading: (state) => state.IsAllInvestmentsTableLoading,
  getAllInvestmentsCount: (state) => state.allInvestmentsCount,
  getInvestmentStatusChoices: (state) => state.InvestmentStatusChoices,
  getCashcallMultipleResponse: (state) => state.cashcallMultipleResponse,
};

const mutations = {
  setDocuSign: (state, payload) => {
    state.docuSign.message = payload?.message;
    state.docuSign.status = payload?.status;
    state.docuSign.documentId = payload?.document_id;
    state.docuSign.documentTemplateName = payload?.document_template_name;
  },

  setAllInvestments: (state, data) => {
    state.allInvestments = data;
  },
  setIsAllInvestmentsTableLoading: (state, payload) => {
    state.IsAllInvestmentsTableLoading = payload;
  },
  setAllInvestmentsCount: (state, payload) => {
    state.allInvestmentsCount = payload;
  },
  setInvestmentStatusChoices: (state, payload) => {
    state.InvestmentStatusChoices = payload;
  },
  setCashcallMultipleResponse: (state, payload) => {
    state.cashcallMultipleResponse = payload;
  },
};

const actions = {
  updateInvestment({ dispatch }, data) {
    const { investment_id } = data;
    delete data.investment_id;

    return new Promise((resolve, reject) => {
      axiosCore.patch(`investments/${investment_id}/`, data).then(
        (response) => {
          dispatch('triggerAlert', {
            show: true,
            type: 'success',
            message: 'investment updated',
          });
          resolve(response);
        },
        (error) => {
          dispatch('triggerAlert', {
            show: true,
            type: 'danger',
            message: 'There was an error when the request was performed',
          });
          reject(error);
        },
      );
    });
  },
  validateDocuSign({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosCore.get(`/investment/${payload.id}/validate-sending-to-docusign`)
        .then(
          (response) => {
            commit('setDocuSign', response.data);
            resolve(response);
          },
          (error) => {
            reject(error);
          },
        );
    });
  },
  sendInvestmentEmail({ commit }, investmentId) {
    return new Promise((resolve, reject) => {
      axiosCore.post(`/investment/${investmentId}/send_email`)
        .then(
          (response) => {
            commit('setDocuSign', response.data);
            resolve(response);
          },
          (error) => {
            reject(error);
          },
        );
    });
  },
  async fetchAllInvestments({ commit, dispatch }, {
    perPage, page, searchParam, selectedFilters, order,
  }) {
    commit('setIsAllInvestmentsTableLoading', true);
    try {
      const response = await axiosCore.get(`/investment/list-core?${searchParam}page=${page}&page_size=${perPage}${selectedFilters}${order}`);
      commit('setAllInvestments', response.data?.results);
      commit('setAllInvestmentsCount', response.data?.count);
    } catch (error) {
      dispatch('triggerAlert', {
        show: true,
        type: 'danger',
        message: error.message,
      });
    } finally {
      commit('setIsAllInvestmentsTableLoading', false);
    }
  },
  async exportInvestmentsTable({ dispatch }, {
    perPage, page, searchParam, selectedFilters, order,
  }) {
    try {
      // override investment table export with old endpoint
      // const response = await axiosCore.get('investments/?&fields=investment.id,investor.name,fundraising.name,investment.status,investment.committed_amount,investment.fees_percentage,investment.fees_type,investment.fees_amount,investment.creation_datetime,fundraising.id,fundraising.creation_datetime,fundraising.currency,investor.id,investor.investment_offer,investor.kyc_status,investor.type,investor.subtype,investor.mangopay_relation_id&format=csv');
      const response = await axiosCore.get(`/investment/list-core?${searchParam}page=${page}&page_size=${perPage}${selectedFilters}${order}&format=csv`);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'export.csv');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      dispatch('triggerAlert', {
        show: true,
        type: 'danger',
        message: error.message,
      });
    }
  },
  async cashcallMultipleBills({ dispatch, commit }, bills) {
    try {
      const response = await axiosCore.post('investment/cash-call-multiple', bills);
      commit('setCashcallMultipleResponse', response.data);
      dispatch('triggerAlert', {
        show: true,
        type: 'success',
        message: 'The data is sent.',
      });
    } catch (error) {
      dispatch('triggerAlert', {
        show: true,
        type: 'danger',
        message: 'There was an error. Probably no bills were cashcalled.',
      });
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
