import { createRouter, createWebHistory } from 'vue-router';
import Cookies from 'vue-cookies';
import JwtToken from '@/plugins/jwt';
import routes from '@/router/routes.js';

import { store } from '@/store/index.js';

export const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_PATH || undefined),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const accessAllPages = ['Login'];

  const raw_refresh_token = Cookies.get('core_ort_jwt_refresh_token');
  if (raw_refresh_token) {
    const jwt_refresh_token = new JwtToken(raw_refresh_token);
    if (jwt_refresh_token.is_expired()) {
      Cookies.remove('core_ort_jwt_access_token');
      Cookies.remove('core_ort_jwt_refresh_token');
      router.push('/login');
    } else if (store.getters.isOrtStaff === false) {
      window.location = import.meta.env.VITE_PLATFORM_DOMAIN;
    } else if (to.name === 'Login') {
      router.push('/');
    } else {
      next();
    }
  } else {
    const toAccessAllPages = accessAllPages.includes(to.name);
    if (toAccessAllPages) {
      next();
    } else {
      router.push('/login');
    }
  }
});

export default router;
