<template>
  <router-link v-if="to !== undefined && to != null && !loading" :to="to"
    ><button
      v-if="!loading"
      :class="'button ' + 'button-' + size + ' button-' + local_color"
      v-bind:style="style_object"
      @click="clicked"
      :disabled="disabled"
    >
      <slot></slot></button
  ></router-link>
  <button
    v-else-if="type == 'see-more'"
    variant="link"
    :class="`button-auto button-more d-block btn btn-secondary button button-${size} button-${local_color}`"
    v-bind:style="style_object"
    @click="clicked"
    :disabled="disabled"
    ><slot></slot
  ></button>
  <button
    v-else-if="!loading"
    :class="'button ' + 'button-' + size + ' button-' + local_color"
    v-bind:style="style_object"
    @click="clicked"
    :disabled="disabled"
  >
    <slot></slot>
  </button>
  <button
    v-else
    :class="'button ' + 'button-' + size + 'button-grey btn-loading'"
    v-bind:style="style_object"
    @click="clicked"
  >
    <loading :loading="loading" :button="true" color="white"></loading>
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    color: {
      type: String,
      required: false,
      default: 'grey',
    },
    to: {
      type: Object,
      required: false,
    },
    size: {
      type: String,
      required: false,
      default: 'fluid',
    },
    text: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: '',
    },
    focused: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      style_object: {},
      local_color: this.color,
    };
  },
  watch: {
    color(newVal) {
      this.local_color = newVal;
    },
    focused(newVal) {
      this.focused = newVal;
      this.toggleFocus(newVal);
    },
  },
  methods: {
    clicked() {
      this.$emit('click');
      if (this.to != undefined) {
        this.$router.push(this.to);
      }
    },
    toggleFocus(focused) {
      if (focused) {
        switch (this.color) {
          case 'purple-white':
            this.style_object.backgroundColor = '#8471b0';
            this.style_object.color = 'white';
            break;
          case 'yellow-white':
            this.local_color = 'yellow';
            break;
          default:
            break;
        }
      } else {
        this.style_object = {};
        this.local_color = this.color;
      }
    },
    stylizeTextButton() {
      if (this.text) {
        this.style_object.backgroundColor = 'transparent';
        this.style_object.color = '#ef6861';
        this.style_object.textAlign = 'right';
        this.style_object.height = 'auto';
        this.style_object.padding = '0';
        this.style_object.verticalAlign = 'top';
      }
    },
  },
  mounted() {
    this.toggleFocus(this.focused);
    this.stylizeTextButton();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

@mixin linear-gradient($to, $primary, $secondary) {
  background: linear-gradient(to $to, $primary, $secondary 150%) !important;
  box-shadow: 0 5px 8px 0 rgba($purple-color, 0.5);
  color: $white-color;
  transition: box-shadow 0.2s ease, background 0.2s ease;
  &:hover {
    background: linear-gradient(to $to, rgba($primary, 0.9), rgba($secondary, 0.9) 150%) !important;
    box-shadow: 0 3px 3px 0 rgba($purple-color, 0.5);
  }
  &:active {
    background: linear-gradient(to $to, $primary, $secondary 150%) !important;
  }
  &:focus {
    background: linear-gradient(to $to, $primary, $secondary 150%) !important;
    &:hover {
      background: linear-gradient(
        to $to,
        rgba($primary, 0.9),
        rgba($secondary, 0.9) 150%
      ) !important;
      box-shadow: 0 3px 3px 0 rgba($purple-color, 0.5);
    }
  }
}
.btn-loading {
  display: flex;
  justify-content: center;
}
.button {
  cursor: pointer;
  border-radius: 5px;
  color: $white-color !important;
  height: 35px;
  font: $main-font-bold;
  align-items: flex-start;
  white-space:pre-wrap;
  vertical-align: middle;
  transition: background 0.3s ease, color 0.3s ease;
  border: none;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  &:focus {
    border: none;
    outline: none;
  }
  &-fluid {
    width: 100%;
  }
  &-large {
    width: 75%;
  }
  &-medlarge {
    width: 65%;
  }
  &-medium {
    width: 50%;
  }
  &-small {
    width: 25%;
  }
  &-auto {
    width: auto;
  }
}

.button-grey {
  background-color: $grey-color !important;
  &:hover,
  &:focus,
  &:active {
   background-color: $black-grey-color;
  }
}

.button-red {
  background-color: $red-color !important;
  &:hover,
  &:focus,
  &:active {
    background-color: $red-darker-color !important;
  }
  &-transparent {
    background: none;
    border: 1px solid $white-color;
    color: $white-color;
    &:hover,
    &:focus,
    &:active {
      background-color: $white-color;
      color: $red-color;
    }
  }
  &-white {
    background-color: $white-color !important;
    color: $red-color !important;
    border: 1px solid $red-color;
    &:hover,
    &:focus,
    &:active {
      color: $white-color !important;
      background-color: $red-color !important;
    }
  }
  &-gradient {
    @include linear-gradient(right, $red-color, $purple-color);
  }
}

.button-green {
  background-color: $green-color !important;
  &:hover,
  &:focus,
  &:active {
    background-color: $green-darker-color !important;
  }
  &-white {
    background-color: $white-color !important;
    color: $green-color !important;
    border: 1px solid $green-color;
    &:hover,
    &:focus,
    &:active {
      color: $white-color !important;
      background-color: $green-color !important;
    }
  }
}

.button-blue {
  background-color: $blue-color !important;
  &:hover,
  &:focus,
  &:active {
    background-color: $blue-darker-color !important;
  }
  &-white {
    background-color: $white-color !important;
    color: $blue-color !important;
    border: 1px solid $blue-color;
    &:hover,
    &:focus,
    &:active {
      color: $white-color !important;
      background-color: $blue-color !important;
    }
  }
}

.button-yellow {
  background-color: $yellow-color !important;
  &:hover,
  &:focus,
  &:active {
    background-color: $yellow-darker-color !important;
  }
  &-white {
    background-color: $white-color !important;
    color: $yellow-color !important;
    border: 1px solid $yellow-color;
    &:hover,
    &:focus,
    &:active {
      color: $white-color !important;
      background-color: $yellow-color !important;
    }
  }
}

.button-purple {
  background-color: $purple-color !important;
  &:hover,
  &:focus,
  &:active {
    background-color: $purple-darker-color !important;
  }
  &-white {
    background-color: $white-color !important;
    color: $purple-color !important;
    border: 1px solid $purple-color;
    &:hover,
    &:focus,
    &:active {
      color: $white-color !important;
      background-color: $purple-color !important;
    }
  }
  &-transparent {
    background: none !important;
    border: 1px solid $white-color;
    color: $white-color !important;
    &:hover,
    &:focus,
    &:active {
      background-color: $white-color !important;
      color: $purple-color;
    }
  }
  &-gradient {
    @include linear-gradient(right, $purple-color, $red-color);
  }
}

.button-transparent {
  background: none;
  border: none;
  color: $grey-color !important;
  &:hover,
  &:active,
  &:focus {
   color: $black-grey-color !important;
  }
}

.button-more {
  text-decoration: none;
  color: $black-grey-color;
  transition: color 0.3s ease;
  padding-left: 0;
  color: $red-color;
  font: $main-font-bold;
  &:hover {
    text-decoration: none;
  }
}
.button-white {
  background-color: $white-color !important;
  border: 1px solid $grey-color !important;
  color: $black-color !important;
  transition: border-color 0.2s ease;
  &:hover,
  &:focus,
  &:active {
    border: 1px solid $grey-mid-dark-color !important;
  }
}
</style>
