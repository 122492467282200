import { axiosCore } from '@/plugins/axios';

const state = {
  allFundraisings: [],
  IsAllFundraisingsTableLoading: false,
  allFundraisingsCount: 0,
  FundraisingStatusChoices: [],
};

const getters = {
  getAllFundraisings: (state) => state.allFundraisings,
  getIsAllFundraisingsTableLoading: (state) => state.IsAllFundraisingsTableLoading,
  getAllFundraisingsCount: (state) => state.allFundraisingsCount,
  getFundraisingStatusChoices: (state) => state.FundraisingStatusChoices,
};

const mutations = {
  setAllFundraisings: (state, data) => {
    state.allFundraisings = data;
  },
  setIsAllFundraisingsTableLoading: (state, payload) => {
    state.IsAllFundraisingsTableLoading = payload;
  },
  setAllFundraisingsCount: (state, payload) => {
    state.allFundraisingsCount = payload;
  },
  setFundraisingStatusChoices: (state, payload) => {
    state.FundraisingStatusChoices = payload;
  },
};

const actions = {
  async fetchAllFundraisings({ commit, dispatch }, {
    perPage, page, searchParam, selectedFilters, order,
  }) {
    commit('setIsAllFundraisingsTableLoading', true);
    try {
      const response = await axiosCore.get(`/fundraisings/list-core?${searchParam}page=${page}&page_size=${perPage}${selectedFilters}${order}`);
      commit('setAllFundraisings', response.data?.results);
      commit('setAllFundraisingsCount', response.data?.count);
    } catch (error) {
      dispatch('triggerAlert', {
        show: true,
        type: 'danger',
        message: error.message,
      });
    } finally {
      commit('setIsAllFundraisingsTableLoading', false);
    }
  },
  async exportFundraisingsTable({ dispatch }, {
    perPage, page, searchParam, selectedFilters, order,
  }) {
    try {
      const response = await axiosCore.get(`/fundraisings/list-core?${searchParam}page=${page}&page_size=${perPage}${selectedFilters}${order}&format=csv`);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'export.csv');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      dispatch('triggerAlert', {
        show: true,
        type: 'danger',
        message: error.message,
      });
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
