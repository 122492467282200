/**
 * JSDoc is a markup language used to annotate JavaScript source code files.
 * It is used to add information about the code, eg what it does, what parameters it takes, what it returns.
 * JSDoc is a lighter-weight alternative to TypeScript and does not force you to use types.
 * We are adopting JSDoc in our heavily-used stores to make it easier to use in multiple components.
 * This lets us know what each store method/property takes in and returns.
 *
 * For more info on how we use JSDoc see this: https://www.notion.so/oneragtime/a56830e220b6466dac5d9892610bcb7e
 *
 * The official documentation of JSDoc can be found here: https://jsdoc.app/
 */

/** @typedef {import("@/types/accounts/users.js").User} User */
/** @typedef {import("@/types/accounts/users.js").CreatedUser} CreatedUser */
/** @typedef {import("@/types/accounts/users.js").UsersSearched} UsersSearched */
/** @template T; @typedef {import("src/types/utils.js").PaginatedResults<T>} PaginatedResults<T> */

import { axiosCore } from '@/plugins/axios';
import { errorAlert, successAlert } from '@/ort-lib/utils/utils';

const actions = {
  /**
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {{
   *  search?: string; // Substring of the user's name or email.
   *  page?: number; // The page of the results to return.
   *  page_size?: number; // The number of results per page.
   * }} params - The search parameters.
  */
  async searchUsers(context, { search = '', page = 1, page_size = 10 }) {
    const endpoint = `/users/list-core?page=${page}&page_size=${page_size}&search=${search}`;
    /** @type {{data: PaginatedResults<UsersSearched>}} */
    const response = await axiosCore.get(endpoint);
    return response.data.results;
  },
  /**
   * Resend invite email to a user
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {number} userId - The ID of the user.
  */
  async sendUserInviteEmail({ commit }, userId) {
    try {
      const response = await axiosCore.post(`/auth/users/${userId}/resend-invite`);
      return successAlert('User invitation resent successfully!');
    } catch (error) {
      return errorAlert('Failed to resend invitation email.', error);
    }
  },
  /**
   * Create a new User
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {{
   *  first_name: string;
   *  last_name: string;
   *  phone_number: string;
   *  email?: string;
   * }} payload - The user's data.
  */
  async createUser({ commit }, payload) {
    try {
      /** @type {{ data: CreatedUser }} */
      const response = await axiosCore.post('/auth/users/', payload);
      successAlert('User created successfully!');
      return response.data;
    } catch (error) {
      errorAlert('Failed to create user.', error);
    }
  },
  /**
  * Delete user.
  * @param {Object} context - Implicit parameter for vuex actions.
  * @param {number} userId - The ID of the user.
  * @returns {Promise<boolean>}
 */
  async deleteUser({ commit }, userId) {
    try {
      await axiosCore.delete(`/auth/users/${userId}/`);
      return successAlert('User deleted successfully!');
    } catch (error) {
      return errorAlert('Failed to delete user.', error);
    }
  },
};

/** @typedef {typeof actions} Actions */

/**
 * @module userInfo
 * @typedef {Object} UserInfoStore
 * @property {Actions} actions
*/

export default {
  actions,
};
