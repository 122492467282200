// import axios from 'axios';
import { axiosCore } from '@/plugins/axios';

export default {
  methods: {
    async apiGet(url) {
      return await axiosCore.get(url);
    },
    async apiDel(url) {
      return await axiosCore.delete(url);
    },
    async apiPatch(url, data) {
      return await axiosCore.patch(url, data);
    },
    async apiPost(url, data, config) {
      if (config)
        return await axiosCore.post(url, data, config);
      return await axiosCore.post(url, data);
    },
  },
};
