import { axiosCore } from '@/plugins/axios';

const state = {
  KYCOld: {},
  isKYCLoading: false,
};

const getters = {
  isKYCLoading: (state) => state.isKYCLoading,
  KYCOld: (state) => state.KYCOld,
};

const actions = {
  async fetchPartnerKYC({ commit }, partnerId) {
    commit('startLoading', true);
    try {
      const response = await axiosCore.get(`partners/${partnerId}/kyc`);
      commit('setKYCOld', response.data);
    } catch (error) {
      throw new Error(error.message);
    } finally {
      commit('endLoading');
    }
  },
  async getInvestorKYC({ commit, dispatch }, investorId) {
    commit('startLoading');
    try {
      const response = await axiosCore.get(`/investors/${investorId}/?fields=kyc`);
      commit('setKYCOld', response.data);
      return response.data;
    } catch (error) {
      dispatch('dangerAlert', `${error}`);
    } finally {
      commit('endLoading');
    }
  },
  async fetchStartupKYC({ commit, dispatch }, startupId) {
    commit('startLoading');
    try {
      const response = await axiosCore.get(`/startups/${startupId}/kyc`);
      commit('setKYCOld', response.data);
      return response.data;
    } catch (error) {
      dispatch('dangerAlert', `${error}`);
    } finally {
      commit('endLoading');
    }
  },
  async getKYCOld({ commit, dispatch }, KYCId) {
    commit('startLoading');
    try {
      const response = await axiosCore.get(`/kycs/${KYCId}/`);
      commit('setKYCOld', response.data);
    } catch (error) {
      dispatch('dangerAlert', `${error}`);
    } finally {
      commit('endLoading');
    }
  },
  async updateKYCOld({ commit, dispatch }, data) {
    commit('startLoading');
    try {
      const response = await axiosCore.patch(`/kycs/${data.id}/`, data);
      commit('setKYCOld', response.data);
      dispatch('successAlert', 'KYC updated successfully');
    } catch (error) {
      dispatch('dangerAlert', `${error}`);
    } finally {
      commit('endLoading');
    }
  },
};

const mutations = {
  setKYCOld: (state, KYC) => (state.KYCOld = KYC),
  endLoading: (state) => (state.isKYCLoading = false),
  startLoading: (state) => (state.isKYCLoading = true),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
