import { axiosCore } from '@/plugins/axios';
import { getAction } from '@/utils/jsdoc';

const successAlert = getAction('successAlert');
const dangerAlert = getAction('dangerAlert');

const state = {
  valuations: [],
  isValuationsLoading: false,
  startupEvents: [],
  isEventsLoading: false,
};

const getters = {
  getValuations: (state) => state.valuations,
  getIsValuationsLoading: (state) => state.isValuationsLoading,
  getEvents: (state) => state.startupEvents,
  getIsLoading: (state) => state.isEventsLoading,
};

const actions = {
  async updateStartupTeamMember({ commit }, { startupId, relationshipId, ...data }) {
    try {
      const response = await axiosCore.patch(`/relationships/startup/${relationshipId}/`, data);
      return successAlert('The team member was successfully updated!');
    } catch (error) {
      return dangerAlert('The team member could not be updated!');
    }
  },

  async fetchValuations({ commit, dispatch }) {
    const url = 'events/valuations';
    commit('setIsValuationsLoading', true);
    let response;
    try {
      response = await axiosCore.get(url);
      commit('setValuations', response.data);
    } catch (error) {
      dispatch('dangerAlert', error.message);
    } finally {
      commit('setIsValuationsLoading', false);
      return response;
    }
  },

  async fetchEvents({ commit, dispatch }, searchParam) {
    const url = `events/startup?${searchParam}`;
    commit('setIsEventsLoading', true);
    let response;
    try {
      response = await axiosCore.get(url);
      commit('setStartupEvents', response.data);
    } catch (error) {
      dispatch('dangerAlert', error.message);
    } finally {
      commit('setIsEventsLoading', false);
      return response;
    }
  },

  async createEvent({ commit, dispatch }, payload) {
    const url = 'events/startup';
    commit('setIsEventsLoading', true);
    try {
      await axiosCore.post(url, payload);
    } catch (error) {
      dispatch('dangerAlert', error.message);
    } finally {
      commit('setIsEventsLoading', false);
    }
  },

  async fetchEvent({ commit, dispatch }, eventId) {
    const url = `events/startup/${eventId}`;
    commit('setIsEventsLoading', true);
    let response;
    try {
      response = await axiosCore.get(url);
      commit('setStartupEvents', response.data);
    } catch (error) {
      dispatch('dangerAlert', error.message);
    } finally {
      commit('setIsEventsLoading', false);
      return response;
    }
  },

  async updateEvent({ commit, dispatch }, { eventId, payload }) {
    const url = `events/startup/${eventId}`;
    commit('setIsEventsLoading', true);
    try {
      await axiosCore.patch(url, payload);
    } catch (error) {
      dispatch('dangerAlert', error.message);
    } finally {
      commit('setIsEventsLoading', false);
    }
  },

  async deleteEvent({ commit, dispatch }, eventId) {
    const url = `events/startup/${eventId}`;
    commit('setIsEventsLoading', true);
    try {
      await axiosCore.delete(url);
    } catch (error) {
      dispatch('dangerAlert', error.message);
    } finally {
      commit('setIsEventsLoading', false);
    }
  },

};

const mutations = {
  setValuations: (state, data) => { state.valuations = data; },
  setIsValuationsLoading: (state, data) => { state.isValuationsLoading = data; },
  setStartupEvents: (state, data) => { state.startupEvents = data; },
  setIsEventsLoading: (state, data) => { state.isEventsLoading = data; },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
