<template>
  <div class="dropdown" v-click-outside="clickedOutside">
    <div class="title" @click="clicked"><slot name="title"></slot></div>
    <div class="content" v-if="drop"><slot name="content"></slot></div>
  </div>
</template>

<script>
export default {
  name: 'Dropdown',
  data() {
    return {
      drop: false,
    };
  },
  methods: {
    clicked() {
      this.drop = !this.drop;
    },
    clickedOutside() {
      this.drop = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

.dropdown {
  cursor: pointer;
  .title {
    color: $grey-color;
    font-size: $medium-font-size;
    transition: color 0.3s ease;
    &:hover,
    &:focus,
    &:active {
      color: $black-grey-color;
    }
  }
  .content {
    position: absolute;
    border-radius: 2px;
    right: 0px;
    background-color: $white-color;
    min-width: 160px;
    border: 1px solid $grey-color;
    z-index: 1;
  }
}
</style>
