/**
 * JSDoc is a markup language used to annotate JavaScript source code files.
 * It is used to add information about the code, eg what it does, what parameters it takes, what it returns.
 * JSDoc is a lighter-weight alternative to TypeScript and does not force you to use types.
 * We are adopting JSDoc in our heavily-used stores to make it easier to use in multiple components.
 * This lets us know what each store method/property takes in and returns.
 *
 * For more info on how we use JSDoc see this: https://www.notion.so/oneragtime/a56830e220b6466dac5d9892610bcb7e
 *
 * The official documentation of JSDoc can be found here: https://jsdoc.app/
 */

/** @typedef {import("src/ort-lib/types/kyc/kyc").KYCData} KYCData */
/** @typedef {StartupData["kyc"] KYC} */

import { axiosCore } from '@/plugins/axios';
import { isEmpty } from '@/ort-lib/utils/validators';
import { errorAlert, successAlert } from '@/ort-lib/utils/utils';

/**
 * @typedef {{
 *  forceFetchKYC: boolean;
 *  currentKYCId: number;
 *  kycData: Object.<number, KYCData>;
 * }} State
*/

/** @type {State} */
const state = {
  forceFetchKYC: false,
  currentKYCId: 0,
  kycData: {
    0: {
      kyc: {},
    },
  },
};

const getters = {
  /** @param {State} state */
  currentKYCId: (state) => state.currentKYCId,
  /** @param {State} state */
  KYCData: (state) => state.kycData[state.currentKYCId],
  /** @param {State} state */
  KYC: (state) => state.kycData[state.currentKYCId].kyc,
};

const actions = {
  /**
   * Get a KYC.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {number} kycId - The ID of the KYC.
  */
  async getKYC({ commit }, kycId) {
    try {
      if (isEmpty(state.kycData[kycId]?.kyc) || state.forceFetchKYC) {
        /** @type {{data: KYC}} */
        const response = await axiosCore.get(`kycs/${kycId}/`);
        commit('setKYCData', { kycId, kycData: { kyc: response.data } });
        commit('setForceFetchKYC', false);
      }
      commit('setCurrentKYCId', kycId);
      return state.kycData[kycId].kyc;
    } catch (error) {
      errorAlert('The KYC could not be fetched!', error);
    };
  },
  /**
   * Update information about the KYC.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {{
   *  kycId: number;
   *  other_fields: any;
   * }} payload - The KYC's data.
  */
  async updateKYC({ commit, dispatch }, { kycId, ...data }) {
    try {
      await axiosCore.patch(`/kycs/${kycId}/`, data);
      successAlert('The KYC was successfully updated!');
      commit('setForceFetchKYC', true);
      await dispatch('getKYC', kycId);
      commit('setCurrentKYCId', kycId);
      return state.kycData[kycId].kyc;
    } catch (error) {
      errorAlert('The KYC could not be updated!', error);
    };
  },
  /**
   * Submit a KYC.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {number} kycId - The ID of the KYC.
   * @returns {Promise<boolean>}
  */
  async submitKYC({ commit, dispatch }, kycId) {
    try {
      await axiosCore.patch(`kycs/${kycId}/submit/`, {});
      commit('setForceFetchKYC', true);
      await dispatch('getKYC', kycId);
      commit('setCurrentKYCId', kycId);
      return successAlert('The KYC was successfully submitted!');
    } catch (error) {
      return errorAlert('The KYC could not be submitted!', error);
    };
  },
};

const mutations = {
  /**
   * The kyc id to associate with the getters.
   * @param {State} state - Implicit parameter for vuex mutations.
   * @param {number} kycId - ID of the KYC.
  */
  setCurrentKYCId: (state, kycId) => state.currentKYCId = +kycId,
  /**
   * Updates the KYC data using supplied fields for the given KYC ID.
   * @param {State} state - Implicit parameter for vuex mutations.
   * @param {number} kycId - ID of the KYC.
   * @param {KYC} kycData - Data to set for the KYC.
  */
  setKYCData: (state, { kycId, kycData }) => {
    state.kycData[kycId] = { ...state.kycData[kycId], ...kycData };
  },
  /**
   * Flag which defines if cached data should be refetched or not.
   * @param {State} state - Implicit parameter for vuex mutations.
   * @param {boolean} fetch - Whether to force fetch or not.
  */
  setForceFetchKYC: (state, fetch) => {
    state.forceFetchKYC = fetch;
  },
};

/** @typedef {typeof getters} Getters */
/** @typedef {typeof actions} Actions */
/** @typedef {typeof mutations} Mutations */

/**
 * @module kyc
 * @typedef {Object} KYCStore
 * @property {State} state
 * @property {Getters} getters
 * @property {Actions} actions
 * @property {Mutations} mutations
 */

export default {
  state,
  getters,
  actions,
  mutations,
};
