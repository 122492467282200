import {
  setAmount,
  getPercentageChange,
  formatNumber,
} from '@/utils/formatters.js';
import { setCompactAmount } from '@/ort-lib/utils/formatters';
import { isEmpty } from '@/ort-lib/utils/validators';

export default {
  methods: {
    setAmount,
    setCompactAmount,
    getPercentageChange,
    isEmpty,
    formatNumber,
    formatDecimal(num) {
      if (!this.isEmpty(num)) {
        num = num
          .toLocaleString('en-US', { maximumFractionDigits: 5 })
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
          .toString();
        if (num.includes('.')) {
          const num_after_split = num.split('.');
          num_after_split[1] = num_after_split[1].replace(/,/g, '');
          num = `${num_after_split[0]}.${num_after_split[1]}`;
        }
      }
      return num;
    },
    capitalize(str) {
      return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
    },
    formatAmount(amount, currency) {
      const formatted_amount = Intl.NumberFormat('en', {
        style: 'currency',
        currency,
      }).format(amount);
      return formatted_amount;
    },
    formatAmountNoDecimals(amount, currency) {
      const formattedAmount = Intl.NumberFormat('en', {
        style: 'currency',
        currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(amount);
      return formattedAmount;
    },
    amountToNumber(amount) {
      if (typeof amount === 'string') {
        const number = Number(amount.replace(/[^0-9\.-]+/g, ''));
        return number;
      }
      return amount;
    },
    // deprecated please use MomentJs
    formatDate(date) {
      let formated_date = null;
      if (date !== null && date !== undefined) {
        if (typeof date !== 'string') {
          formated_date = date.toISOString().split('T')[0];
        } else {
          formated_date = date.split('T')[0];
        }
        return formated_date;
      }
    },
    addCurrency(amount, currency) {
      amount = this.formatNumber(Number(amount).toFixed(2));
      currency = (currency === 16 || currency === 'EUR') ? 'EUR' : 'USD';
      return `${currency === 'EUR' ? '€' : '$'}${amount}`;
    },
    humanize(str) {
      if (str !== undefined && str !== '' && str !== null) {
        const frags = str.split('_');
        for (let i = 0; i < frags.length; i++) {
          frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
        }
        return frags.join(' ');
      }
      return str;
    },
    getMultichoiceField(parent, field) {
      if (parent[field] !== null && parent[field] !== undefined && parent[field]) {
        const tmp_list = parent[field];
        parent[field] = [];
        for (const option in tmp_list) {
          if (!this.isEmpty(tmp_list[option])) {
            if (typeof tmp_list[option] === 'number') {
              parent[field].push(tmp_list[option]);
            } else if (typeof tmp_list[option] === 'object') {
              parent[field].push(tmp_list[option].id);
            }
          }
        }
      }
    },
    setMultichoiceField(parent, field, selectionList) {
      if (
        parent[field] !== null
        && parent[field] !== undefined
        && parent[field]
        && selectionList.length !== 0
      ) {
        const tmp_list = parent[field];
        parent[field] = [];
        for (let ind = 0; ind < selectionList.length; ind++) {
          if (tmp_list.includes(selectionList[ind].id)) {
            parent[field].push(selectionList[ind]);
          }
        }
      }
    },
    getNameFromSelect(id, list) {
      for (const ind in list) {
        if (list[ind].value === id) {
          return list[ind].text;
        }
      }
    },
    disableDashlaneAutocomplete(attribute, value) {
      const selector = `[${attribute}="${value}"]`;
      const input = document.querySelector(selector);
      if (!this.isEmpty(input)) {
        input.setAttribute('autocomplete', 'off');
        input.setAttribute('type', 'avoidAutocomplete');
        input.removeAttribute('data-kwimpalastatus');
        input.removeAttribute('data-kwimpalaid');
      }
    },
    showEdit(boolean) {
      this.edit = boolean;
    },
  },
};
