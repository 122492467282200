import { axiosCoreNoToken } from '@/plugins/axios.js';
import { store } from '@/store';
import { router } from '@/router/index.js';

export const GOOGLE_USER_ID = import.meta.env.VITE_GOOGLE_USER_ID;

function postGoogleJWT(data) {
  return new Promise((resolve, reject) => {
    axiosCoreNoToken.post('auth/google/', data).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      },
    );
  });
}

export function handleCredentialResponse(response) {
  store.commit('setLoadingWhileLogin', true);
  const data = { token: response.credential };
  postGoogleJWT(data).then(
    (response) => {
      const accesstoken = response.data.access;
      const refreshtoken = response.data.refresh;
      store.commit('setAccessToken', accesstoken);
      store.commit('setRefreshToken', refreshtoken);
      store.commit('setLoadingWhileLogin', false);
      router.push({ name: 'Dashboard' });
    },
    (error) => {
      store.commit('setLoadingWhileLogin', false);
      store.commit('setLoginFeedback', error.response.data);
    },
  );
}

window.handleCredentialResponse = handleCredentialResponse;
