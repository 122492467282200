import { axiosCore } from '@/plugins/axios';

const state = {
  kycFund: {},
  kycBeneficialOwner: [],
  kycFundTaxCountry: [],
};

const getters = {
  getKycFund: (state) => state.kycFund,
  getKycBeneficialOwner: (state) => state.kycBeneficialOwner,
  getKycFundTaxCountry: (state) => state.kycFundTaxCountry,
};

const mutations = {
  setKycFund: (state, data) => {
    state.kycFund = data;
  },
  setKycBeneficialOwner: (state, data) => { state.kycBeneficialOwner = data; },
  setKycFundTaxCountry: (state, data) => { state.kycFundTaxCountry = data; },
};

const actions = {
  async fetchKycFund({ commit, dispatch }, investorId) {
    const url = `investors/${investorId}/kyc-fund`;
    try {
      const response = await axiosCore.get(url);
      commit('setKycFund', response.data);
    } catch {
      commit('setKycFund', {});
      dispatch('dangerAlert', {
        message: 'Something went wrong trying to get kyc fund.',
      });
    }
  },
  async fetchKycBeneficialOwner({ commit, dispatch, getters }, investorId) {
    const kycFundId = getters.getKycFund.id;
    if (!kycFundId) {
      return;
    }
    const url = `investors/${investorId}/kyc-fund/${kycFundId}/beneficial-owner`;
    try {
      const response = await axiosCore.get(url);
      commit('setKycBeneficialOwner', response.data);
    } catch {
      commit('setKycBeneficialOwner', []);
      dispatch('dangerAlert', {
        message: 'Something went wrong trying to get your Beneficial Owner.',
      });
    }
  },
  async fetchKycFundTaxCountry({ commit, dispatch, getters }, investorId) {
    const kycFundId = getters.getKycFund.id;
    const url = `investors/${investorId}/kyc-fund/${kycFundId}/tax-country`;
    try {
      const response = await axiosCore.get(url);
      commit('setKycFundTaxCountry', response.data);
    } catch {
      commit('setKycFundTaxCountry', []);
      dispatch('dangerAlert', {
        message: 'Something went wrong trying to get your tax country.',
      });
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
