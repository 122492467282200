import { axiosCore } from '@/plugins/axios';

const state = {};

const getters = {};

const actions = {
  buildSubscriptionDocuments({ dispatch }, body) {
    const jsonBody = {
      data_used: JSON.stringify(body.formatedData.data),
      spv: body.formatedData.spv,
      language: body.formatedData.language,
      convert_to_pdf: body.formatedData.convert_to_pdf,
    };
    const url = `/files/document/build/${body.name}/${body.type}/${body.docId}`;
    return new Promise((resolve, reject) => {
      axiosCore.post(url, jsonBody).then(
        (response) => {
          dispatch('triggerAlert', {
            show: true,
            type: 'success',
            message: 'The document was successfully created! Please, refresh the page to see it',
          });
          resolve(response.data);
        },
        (error) => {
          dispatch('triggerAlert', {
            show: true,
            type: 'danger',
            message: 'Error! We could not create the document. Is the template name a valid one?',
          });
          reject(error);
        },
      );
    });
  },
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
