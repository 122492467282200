<template>
  <div :style="cssStyles" :class="badgeClass">
    <img v-if="image" class="ort-badge__img" :src="image">
    <span class="ort-badge__text">{{ text }}</span>
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: 'ORTBadge',
  props: {
    text: {
      type: [String, Number],
    },
    color: {
      type: String,
      default: 'primary',
    },
    icon: {
      type: String,
    },
    iconPosition: {
      type: String,
      validator: (value) => ([
        'left',
        'right',
      ].includes(value)),
      default: 'left',
    },
    image: {
      type: String,
    },
    size: {
      type: String,
      validator: (value) => ([
        'sm',
        'md',
        'lg',
      ].includes(value)),
      default: 'lg',
    },
    isOutlined: {
      type: Boolean,
    },
    shape: {
      type: String,
      validator: (value) => ([
        'rounded',
        'circled',
      ].includes(value)),
      default: 'rounded',
    },
    width: {
      type: String,
      default: 'fit-content',
    },
    maxWidth: {
      type: String,
      default: 'none',
    },
    minWidth: {
      type: String,
      default: 'auto',
    },
  },

  computed: {
    cssStyles() {
      return {
        '--width': this.width,
        '--max-width': this.maxWidth,
        '--min-width': this.minWidth,
      };
    },
    badgeClass() {
      return [
        'ort-badge',
        `ort-badge--${this.size}`,
        `ort-badge--${this.shape}`,
        `ort-badge--${this.ortColor}`,
        {
          'ort-badge--outlined': this.isOutlined,
          'ort-badge--reversed-icon-position': this.iconPosition === 'right',
        },
      ];
    },
    ortColor() {
      const color = this.color.toLowerCase().replace(' ', '_');
      switch (color) {
        case 'validated': case 'valid': case 'success': case 'active': case 'paid': case 'green':
          return 'success';
        case 'pending': case 'waiting_submit': case 'warning': case 'requested': case 'incomplete': case 'created': case 'pending': case 'yellow':
          return 'warning';
        case 'rejected': case 'refused': case 'error': case 'paid_incorrectly': case 'failed': case 'red':
          return 'error';
        case 'portfolio_only': case 'purple': case 'legal':
          return 'purple';
        case 'in_progress': case 'blue':
          return 'blue';
        case 'white':
          return 'white';
        case 'out_of_date': case 'inactive': case 'dormant': default:
          return 'grey';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ort-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  width: var(--width);
  max-width: var(--max-width);
  min-width: var(--min-width);

  &__icon-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12px;
    height: 12px;
  }

  &__img {
    margin-right: 6px;
    width: 16px;
    height: 16px;
    min-height: 16px;
    border-radius: 50%;
  }

  &__icon {
    margin-right: 4px;
    display: flex;
    align-items: center;
  }

  &__text {
    white-space: nowrap;
    font-size: 12px;
    font-weight: 600;
  }

  &--reversed-icon-position {
    flex-direction: row-reverse;

    & .ort-badge__icon {
      margin-right: 0;
      margin-left: 4px;
    }
  }

  &--sm {
    min-width: 47px;
    min-height: 22px;
    padding: 2px;
  }

  &--md {
    min-width: 51px;
    min-height: 22px;
    padding: 3px;
  }

  &--lg {
    min-width: 55px;
    min-height: 26px;
    padding: 5px 10px;
  }

  &--circled {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    min-width: 24px;
    height: 24px;
    min-height: 24px;

    .ort-badge__icon {
      margin: 0;
    }

    &.ort-badge--sm {
      width: 20px;
      min-width: 20px;
      height: 20px;
      padding: 4px
    }

    &.ort-badge--md {
      width: 24px;
      min-width: 24px;
      height: 24px;
      padding: 6px;
    }

    &.ort-badge--lg {
      width: 28px;
      min-width: 28px;
      height: 28px;
      padding: 8px;
    }
  }

  &--success {
    background-color: $green-500;
    color: $green-100;

    & > .ort-badge__icon {
      background-color: $green-100 !important;
    }

    &.ort-badge--outlined {
      background-color: transparent;
      border: 1.5px solid $green-100;
    }
  }

  &--warning {
    background-color: $yellow-500;
    color: $yellow-100;

    & > .ort-badge__icon {
      background-color: $yellow-100 !important;
    }

    &.ort-badge--outlined {
      background-color: transparent;
      border: 1.5px solid $yellow-100;
    }
  }

  &--error {
    background-color: $red-500;
    color: $red-100;

    & > .ort-badge__icon {
      background-color: $red-100 !important;
    }

    &.ort-badge--outlined {
      background-color: transparent;
      border: 1.5px solid $red-100;
    }
  }

  &--blue {
    background-color: $blue-500;
    color: $blue-100;

    & > .ort-badge__icon {
      background-color: $blue-100 !important;
    }

    &.ort-badge--outlined {
      background-color: transparent;
      border: 1.5px solid $blue-100;
    }
  }

  &--purple {
    background-color: $purple-lighter-color;
    color: $white;

    & > .ort-badge__icon {
      background-color: $purple-lighter-color !important;
    }

    &.ort-badge--outlined {
      background-color: transparent;
      border: 1.5px solid $purple-lighter-color;
    }
  }

  &--grey {
    background-color: $grey-700;
    color: $grey-200;

    & > .ort-badge__icon {
      background-color: $grey-200 !important;
    }

    &.ort-badge--outlined {
      background-color: transparent;
      border: 1.5px solid $grey-200;
    }
  }

  &--white {
    background-color: $white;
    color: $grey-100;
    border: 1.5px solid $grey-500;

    & > .ort-badge__icon {
      background-color: $white !important;
    }

    &.ort-badge--outlined {
      background-color: transparent;
    }
  }
}
</style>
