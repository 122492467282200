import { axiosCore } from '@/plugins/axios';

const Login = () => import('@/components/Pages/Login/Login.vue');
const ProfileSettings = () => import('@/components/Pages/ProfileSettings/ProfileSettings.vue');
const Dashboard = () => import('@/components/Pages/Dashboard/Dashboard.vue');
const Investors = () => import('@/components/Pages/Investors/Investors.vue');
const Investor = () => import('@/components/Pages/Investor/Investor.vue');
const Partners = () => import('@/components/Pages/Partners/Partners.vue');
const Partner = () => import('@/components/Pages/Partner/Partner.vue');
const Investments = () => import('@/components/Pages/Investments/Investments.vue');
const Investment = () => import('@/components/Pages/Investment/Investment.vue');
const Startups = () => import('@/components/Pages/Startups/Startups.vue');
const Startup = () => import('@/components/Pages/Startup/Startup.vue');
const Fundraisings = () => import('@/components/Pages/Fundraisings/Fundraisings.vue');
const Valuations = () => import('@/components/Pages/Valuations/Valuations.vue');
const Fundraising = () => import('@/components/Pages/Fundraising/Fundraising.vue');
const Fund = () => import('@/components/Pages/Fundraising/Fund.vue');
const FinancesOld = () => import('@/components/Pages/Finances/FinancesOld.vue');
const Bills = () => import('@/components/Pages/Finances/sections/DashboardBills.vue');
const Payouts = () => import('@/components/Pages/Finances/Payouts.vue');
const Users = () => import('@/components/Pages/CustomUsers/Users.vue');
const CustomUser = () => import('@/components/Pages/CustomUser/CustomUser.vue');
const Tools = () => import('@/components/Pages/Tools/Tools.vue');
const ClientsDashboard = () => import('@/components/Pages/Clients/ClientsDashboard.vue');

function createBeforeEnter(entityType, difRouteStart = '') {
  return async (to, from, next) => {
    try {
      const { id } = to.params;
      await axiosCore.get(`${difRouteStart}/${entityType}/id?id=${id}`)
        .then((response) => {
          if (response.data) {
            next();
          } else {
            next({ name: 'Dashboard' });
          }
        });
    } catch (error) {
      next({ name: 'Dashboard' });
    }
  };
}

const routes = [
  {
    path: '/:patchMatch(.*)',
    redirect: { name: '404' },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/settings',
    name: 'ProfileSettings',
    component: ProfileSettings,
  },
  {
    path: '/investors',
    name: 'Investors',
    component: Investors,
  },
  {
    path: '/investors/:id',
    name: 'Investor',
    component: Investor,
    beforeEnter: createBeforeEnter('investors'),
  },
  {
    path: '/investments',
    name: 'Investments',
    component: Investments,
  },
  {
    path: '/investments/:id',
    name: 'Investment',
    component: Investment,
    beforeEnter: createBeforeEnter('investment'),
  },
  {
    path: '/startups',
    name: 'Startups',
    component: Startups,
  },
  {
    path: '/startups/:id',
    name: 'Startup',
    component: Startup,
    beforeEnter: createBeforeEnter('startups'),
  },
  {
    path: '/fundraisings',
    name: 'Fundraisings',
    component: Fundraisings,
  },
  {
    path: '/fundraisings/:id',
    name: 'Fundraising',
    component: Fundraising,
    beforeEnter: createBeforeEnter('fundraisings'),
  },
  {
    path: '/fund/:id',
    name: 'Fund',
    component: Fund,
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
  },
  {
    path: '/users/:id',
    name: 'CustomUser',
    component: CustomUser,
    beforeEnter: createBeforeEnter('users', '/auth'),
  },
  {
    path: '/finances-old',
    name: 'FinancesOld',
    component: FinancesOld,
  },
  {
    path: '/bills',
    name: 'Bills',
    component: Bills,
  },
  {
    path: '/partners',
    name: 'Partners',
    component: Partners,
  },
  {
    path: '/partners/:id',
    name: 'Partner',
    component: Partner,
    beforeEnter: createBeforeEnter('partners'),
  },
  {
    path: '/valuations',
    name: 'Valuations',
    component: Valuations,
  },
  {
    path: '/tools',
    name: 'Tools',
    component: Tools,
  },
  {
    path: '/payouts',
    name: 'Payouts',
    component: Payouts,
  },
  {
    path: '/clients-dashboard',
    name: 'ClientsDashboard',
    component: ClientsDashboard,
  }
];

export default routes;
