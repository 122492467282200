import { axiosCore } from '@/plugins/axios';
import { errorAlert, successAlert } from '@/ort-lib/utils/utils';

const getters = {
  getToken: (state) => `Bearer ${state.token}`,
  getBankaccounts: (state) => state.bankaccounts,
  getWallets: (state) => state.wallets,
  getLoadingPayout: (state) => state.loadingPayout,
};

const actions = {
  async fetchToken({ commit }) {
    // We make a wrapper around mangopay login to keep permanent keys in backend
    commit('setLoadingPayout', true);
    try {
      const response = await axiosCore.post('v3/mangopay/token');
      if (response.data) {
        commit('setToken', response.data.token);
        commit('setWallets', response.data.wallets);
        commit('setBankaccounts', response.data.bankaccounts);
      }
    } catch (err) {
      errorAlert('Unable to connect to Mangopay', err);
    } finally {
      commit('setLoadingPayout', false);
    }
  },

  async postPayout({ dispatch, getters, commit }, body) {
    // We make a wrapper around mangopay post to avoid handling XSS
    try {
      commit('setLoadingPayout', true);
      body = { ...body, token: getters.getToken };
      const response = await axiosCore.post('v3/mangopay/payout', body);
      if (response.data.ResultMessage) {
        successAlert(`Mangopay responded with message: ${response.data.ResultMessage}`);
      } else {
        successAlert('Posted to Mangopay');
      }
    } catch (err) {
      errorAlert('Unable to post payout to Mangopay', err);
    } finally {
      commit('setLoadingPayout', false);
      dispatch('fetchToken');
    }
  },
};

const mutations = {
  setToken: (state, data) => { state.token = data; },
  setWallets: (state, data) => { state.wallets = data; },
  setBankaccounts: (state, data) => { state.bankaccounts = data; },
  setLoadingPayout: (state, data) => { state.loadingPayout = data; },
};

const state = {
  loadingPayout: false,
  token: '',
  wallets: [],
  bankaccounts: [
    {
      OwnerAddress: {
        AddressLine1: "2 place d'Estienne d'Orves",
        AddressLine2: null,
        City: 'Paris',
        Region: null,
        PostalCode: '75009',
        Country: 'FR',
      },
      IBAN: 'FR7630056009320932002057956',
      BIC: 'CCFRFRPP',
      UserId: 'oneragtime',
      OwnerName: 'ONERAGTIME SASU',
      Type: 'IBAN',
      Id: '151112185',
      Tag: null,
      CreationDate: 1493107566,
      Active: true,
    },
    {
      OwnerAddress: {
        AddressLine1: "2 place Estienne d'Orves",
        AddressLine2: '',
        City: 'Paris',
        Region: 'Ile de France',
        PostalCode: '75009',
        Country: 'FR',
      },
      IBAN: 'FR7630004029990001027323973',
      BIC: 'BNPAFRPPXXX',
      UserId: 'oneragtime',
      OwnerName: 'OneRagtime',
      Type: 'IBAN',
      Id: '505386159',
      Tag: 'OneRagtime SAS - BNP',
      CreationDate: 1550672920,
      Active: true,
    },
    {
      OwnerAddress: {
        AddressLine1: "2 place Estienne d'Orves",
        AddressLine2: '',
        City: 'Paris',
        Region: 'Ile de France',
        PostalCode: '75009',
        Country: 'FR',
      },
      IBAN: 'FR7630004029990001027323973',
      BIC: 'BNPAFRPPXXX',
      UserId: 'oneragtime',
      OwnerName: 'OneRagtime',
      Type: 'IBAN',
      Id: '2036644471',
      Tag: 'OneRagtime SAS - BNP',
      CreationDate: 1638890305,
      Active: true,
    },
    {
      OwnerAddress: {
        AddressLine1: '36 RUE ST-PETERSBOURG',
        AddressLine2: '',
        City: 'Paris',
        Region: 'Ile de France',
        PostalCode: '75008',
        Country: 'FR',
      },
      IBAN: 'FR7630003043800002004000809',
      BIC: 'SOGEFRPP',
      UserId: 'oneragtime',
      OwnerName: 'OneRagtime',
      Type: 'IBAN',
      Id: '2036645735',
      Tag: 'OneRagtime SAS - SOCIETE GENERALE',
      CreationDate: 1638890334,
      Active: true,
    },
    {
      OwnerAddress: {
        AddressLine1: '60 R DE Londres',
        AddressLine2: '',
        City: 'Paris',
        Region: 'Ile de France',
        PostalCode: '75008',
        Country: 'FR',
      },
      IBAN: 'FR7630056009170917032600208',
      BIC: 'CCFRFRPP',
      UserId: 'oneragtime',
      OwnerName: 'OneRagtime',
      Type: 'IBAN',
      Id: '2045297681',
      Tag: 'OneRagtime SAS - HSBC',
      CreationDate: 1639152033,
      Active: false,
    },
    {
      OwnerAddress: {
        AddressLine1: "2 place Estienne d'Orves",
        AddressLine2: '',
        City: 'Paris',
        Region: 'Ile de France',
        PostalCode: '75009',
        Country: 'FR',
      },
      IBAN: 'FR7630004029990001027323973',
      BIC: 'BNPAFRPPXXX',
      UserId: 'oneragtime',
      OwnerName: 'OneRagtime',
      Type: 'IBAN',
      Id: '2420274328',
      Tag: 'OneRagtime SAS - BNP',
      CreationDate: 1652262456,
      Active: true,
    },
    {
      OwnerAddress: {
        AddressLine1: '60 RUE DE LONDRES',
        AddressLine2: '',
        City: 'Paris',
        Region: 'Ile de France',
        PostalCode: '75008',
        Country: 'FR',
      },
      IBAN: 'FR7630056009280928005728535',
      BIC: 'CCFRFRPP',
      UserId: 'oneragtime',
      OwnerName: 'OneRagtime',
      Type: 'IBAN',
      Id: '4018024080',
      Tag: 'OneRagtime SAS - HSBC EUROS SECONDAIRE',
      CreationDate: 1693823261,
      Active: true,
    },
  ],
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
