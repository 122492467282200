export default {
  beforeMount(el, binding, vnode) {
    let i = 0;
    el.addEventListener('animationiteration', () => {
      i++;
      if (i >= 2) {
        i = 0;
        el.classList.remove('animate-logo');
      } else {
        i = 0;
      }
    });

    el.addEventListener('mouseout', () => {
      i++;
    });

    el.addEventListener('mouseover', () => {
      el.classList.add('animate-logo');
    });
  },
  unmounted(el) {
    el.removeEventListener('click', el.event);
    el.removeEventListener('webkitAnimationEnd mozAnimationEnd animationend', el.event);
  },
};
