<template>
  <transition name="fade">
    <div :class="`alert alert-${type}`">
      {{ this.message }}
      <span class="alert-dismiss">&times;</span>
   </div>
  </transition>
</template>

<script>
export default {
  name: 'Alert',
  props: {
    type: {
      type: String,
      required: false,
      default: 'info',
    },
    message: {
      required: true,
    },
  },
  mounted() {
    setTimeout(() => {
      this.$store.dispatch('triggerAlert', { show: false });
    }, 3000);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

.alert {
  font-size: $medium-font-size;
  font-weight: 400;
  position: fixed;
  top: 10px;
  left: 35%;
  z-index: 9999;
  width: 30%;
  text-align: center;
  &-info {
    background-color: white;
    border-color: $blue-lighter-color;
    color: $blue-darker-color;
  }
  &-success {
    background-color: white;
    border-color: $green-lighter-color;
    color: $green-darker-color;
  }
  &-danger {
    background-color: white;
    border-color: $red-lighter-color;
    color: $red-darker-color;
  }
  @media screen and (max-width: 576px) {
    width: 60%;
    left: 20%;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
