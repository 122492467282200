import { axiosCore } from '@/plugins/axios';

const state = {
  investorFundInvestments: [],
  latestFundInvestment: {},
  investorFundInvestmentsLoading: true,
};
const getters = {
  getInvestorFundInvestments: (state) => state.investorFundInvestments,
  getInvestorFundInvestmentsLoading: (state) => state.investorFundInvestmentsLoading,
};

const mutations = {
  setInvestorFundInvestments: (state, data) => {
    state.investorFundInvestments = data;
  },
  setInvestorFundInvestmentsLoading: (state, data) => {
    state.investorFundInvestmentsLoading = data;
  },
};

const actions = {
  async fetchInvestorFundInvestments({ commit, dispatch }, investorId) {
    const url = `investors/${investorId}/investments?is_fund=true`;
    try {
      commit('setInvestorFundInvestmentsLoading', true);
      const response = await axiosCore.get(url);
      commit('setInvestorFundInvestments', response.data);
    } catch {
      commit('setInvestorFundInvestments', {});
      dispatch('dangerAlert', {
        message: 'Something went wrong trying to get kyc fund.',
      });
    } finally {
      commit('setInvestorFundInvestmentsLoading', false);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
