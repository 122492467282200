import { axiosCore } from '@/plugins/axios';

const state = {
  investors: [],
  investorsCount: 0,
  isLoading: false,
};

const getters = {
  getInvestors: (state) => state.investors,
  getInvestorsCount: (state) => state.investorsCount,
  getIsLoading: (state) => state.isLoading,
};

const actions = {
  async fetchInvestors({ commit, dispatch }, { fundraisingId, page, perPage }) {
    const url = `fundraisings/${fundraisingId}/community-members?page=${page}&page_size=${perPage}`;
    commit('setIsLoading', true);
    try {
      const response = await axiosCore.get(url);
      commit('setInvestors', response.data?.results);
      commit('setInvestorsCount', response.data?.count);
    } catch {
      dispatch('dangerAlert', 'Could not edit the Investor status');
    } finally {
      commit('setIsLoading', false);
    }
  },
  async deleteCommunity({ commit, dispatch }, communityId) {
    const url = `fundraisings/community/${communityId}`;
    commit('setIsLoading', true);
    try {
      await axiosCore.delete(url);
    } catch {
      dispatch('dangerAlert', 'Could not delete the Investor from the community');
    } finally {
      commit('setIsLoading', false);
    }
  },
};

const mutations = {
  setInvestors(state, data) { state.investors = data; },
  setInvestorsCount(state, data) { state.investorsCount = data; },
  setIsLoading(state, data) { state.isLoading = data; },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
