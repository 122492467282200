import { axiosCoreNoToken, axiosCore } from '@/plugins/axios';

const actions = {
  queryChoices({}, searchQuery) {
    return axiosCoreNoToken.get(`/choices/?choices=${searchQuery}`);
  },
  getChoices({ commit, getters }, choiceType) {
    return new Promise((resolve, reject) => {
      const choice = getters[`get${choiceType}`];
      if (choice && Object.keys(choice).length) {
        resolve(choice);
      }
      axiosCore.get(`/choices/?choices=${choiceType}`).then(
        (response) => {
          commit(`set${choiceType}`, response.data);
          resolve(response.data);
        },
        (error) => {
          reject(error);
        },
      );
    });
  },
};

export default {
  actions,
};
