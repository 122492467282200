import router from '@/router/index';

const state = {
  loginFormData: { email: '', password: '' },
  loadingWhileLogin: false,
  loginFeedback: false,
  redirectAfterLogin: false,
  redirectUrl: '',
};

const getters = {
  getLoginFormData: (state) => state.loginFormData,
  getLoginFeedback: (state) => state.loginFeedback,
  getLoadingWhileLogin: (state) => state.loadingWhileLogin,
  getRedirectAfterLogin: (state) => state.redirectAfterLogin,
  getRedirectUrl: (state) => state.redirectUrl,
};
const mutations = {
  setLoginFormData: (state, data) => (state.loginFormData = data),
  setLoginFeedback: (state, data) => (state.loginFeedback = data),
  setLoadingWhileLogin: (state, data) => (state.loadingWhileLogin = data),
  setRedirectAfterLogin: (state, data) => (state.redirectAfterLogin = data),
  setRedirectUrl: (state, data) => (state.redirectUrl = data),
};

const actions = {
  completeLogin({ dispatch, getters, commit }) {
    commit('setLoadingWhileLogin', true);
    dispatch('getToken', getters.getLoginFormData).then(
      () => {
        dispatch('triggerAlert', {
          show: true,
          type: 'success',
          message: 'Welcome back!',
        });
        commit('setLoadingWhileLogin', false);
        router.push({ name: 'Dashboard' });
      },
      (error) => {
        commit('setLoginFeedback', error.response.data);
        commit('setLoadingWhileLogin', false);
      },
    );
  },

};

export default {
  state,
  getters,
  actions,
  mutations,
};
