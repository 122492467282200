/**
 * JSDoc is a markup language used to annotate JavaScript source code files.
 * It is used to add information about the code, eg what it does, what parameters it takes, what it returns.
 * JSDoc is a lighter-weight alternative to TypeScript and does not force you to use types.
 * We are adopting JSDoc in our heavily-used stores to make it easier to use in multiple components.
 * This lets us know what each store method/property takes in and returns.
 *
 * For more info on how we use JSDoc see this: https://www.notion.so/oneragtime/a56830e220b6466dac5d9892610bcb7e
 *
 * The official documentation of JSDoc can be found here: https://jsdoc.app/
*/

/** @typedef {import("@/ort-lib/stores/accounts/jwt").Getters} JWTGetters */
/** @typedef {import("@/ort-lib/stores/accounts/jwt").Actions} JWTActions */
/** @typedef {import("@/ort-lib/types/relationships/relationships").Relationships} Relationships */
/** @typedef {Relationships["investors"] UserInvestorRelationship} */
/** @typedef {Relationships["partners"] UserPartnerRelationship} */
/** @typedef {Relationships["startups"] UserStartupRelationship} */


import { axiosCore } from '@/plugins/axios';
import { isEmpty } from '@/ort-lib/utils/validators';
import { errorAlert, successAlert } from '@/ort-lib/utils/utils';

/**
 * @typedef {{
 *  forceFetchRels: boolean;
 *  currentUserRelsId: number;
 *  userRels: Object.<number, Relationships>;
 * }} State
*/

/** @type {State} */
const state = {
  forceFetchRels: false,
  currentUserRelsId: 0,
  userRels: {
    0: {
      investors: [],
      partners: [],
      startups: [],
    },
  },
};

const getters = {
  /** @param {State} state */
  getCurrentUserRelsId: (state) => state.currentUserRelsId,
  /** @param {State} state */
  allFetchedUserRels: (state) => state.userRels,
  /** @param {State} state */
  relatedInvestors: (state) => state.userRels[state.currentUserRelsId].investors,
  /** @param {State} state */
  relatedPartners: (state) => state.userRels[state.currentUserRelsId].partners,
  /** @param {State} state */
  relatedStartups: (state) => state.userRels[state.currentUserRelsId].startups,
};

const actions = {
  /**
   * Get Investor Relationships of the user.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {number} userId - The ID of the user.
  */
  async getRelatedInvestors({ commit }, userId) {
    try {
      if (isEmpty(state.userRels[userId]?.investors) || state.forceFetchRels) {
        /** @type {{ data: UserInvestorRelationship[] }} */
        const response = await axiosCore.get(`/investors/user/${userId}/investor-relationships`);
        commit('setUserRelData', { userId, userRelData: { investors: response.data } });
        commit('setCurrentUserRelsId', userId);
        commit('setForceFetchRels', false);
      }
      return state.userRels[userId].investors;
    } catch (error) {
      errorAlert('Failed to fetch user related investors', error);
    }
  },
  /**
   * Get Partner Relationships of the user.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {number} userId - The ID of the user.
  */
  async getRelatedPartners({ commit }, userId) {
    try {
      if (isEmpty(state.userRels[userId]?.partners) || state.forceFetchRels) {
        /** @type {{ data: UserPartnerRelationship[] }} */
        const response = await axiosCore.get(`/partners/user/${userId}/partner-relationships`);
        commit('setUserRelData', { userId, userRelData: { partners: response.data } });
        commit('setCurrentUserRelsId', userId);
        commit('setForceFetchRels', false);
      }
      return state.userRels[userId].partners;
    } catch (error) {
      errorAlert('Failed to fetch user related partners', error);
    }
  },
  /**
   * Get Startup Relationships of the user.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {number} userId - The ID of the user.
  */
  async getRelatedStartups({ commit }, userId) {
    try {
      if (isEmpty(state.userRels[userId]?.startups) || state.forceFetchRels) {
        /** @type {{ data: UserStartupRelationship[] }} */
        const response = await axiosCore.get(`/startups/user/${userId}/startup-full-relationships`);
        commit('setUserRelData', { userId, userRelData: { startups: response.data } });
        commit('setCurrentUserRelsId', userId);
        commit('setForceFetchRels', false);
      }
      return state.userRels[userId].startups;
    } catch (error) {
      errorAlert('Failed to fetch user related startups', error);
    }
  },
  /**
   * Update UserInvestorRelationship of the user.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {{
   *  userId: number;
   *  relId: number;
   *  is_owner?: boolean;
   *  is_contact?: boolean;
   *  status?: string;
   * }} payload
  */
  async updateInvestorRelationship({ dispatch, commit }, { userId, relId, ...payload }) {
    try {
      const response = await axiosCore.patch(`/relationships/investor/${relId}/`, payload);
      successAlert('Relationship updated successfully!');
      commit('setForceFetchRels', true);
      await dispatch('getRelatedInvestors', userId);
      commit('setCurrentUserRelsId', userId);
      return response.data;
    } catch (error) {
      errorAlert('Failed to update relationship', error);
    }
  },
  /**
   * Update UserPartnerRelationship of the user.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {{
   *  userId: number;
   *  relId: number;
   *  is_owner?: boolean;
   *  is_point_of_contact?: boolean;
   *  status?: string;
   * }} payload
  */
  async updatePartnerRelationship({ dispatch, commit }, { userId, relId, ...payload }) {
    try {
      const response = await axiosCore.patch(`/relationships/partner/${relId}/`, payload);
      successAlert('Relationship updated successfully!');
      commit('setForceFetchRels', true);
      await dispatch('getRelatedPartners', userId);
      commit('setCurrentUserRelsId', userId);
      return response.data;
    } catch (error) {
      errorAlert('Failed to update relationship', error);
    }
 },
  /**
   * Update UserStartupRelationship of the user.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {{
   *  userId: number;
   *  relId: number;
   *  position?: string;
   *  is_owner?: boolean;
   *  is_contact?: boolean;
   *  is_founder?: boolean;
   *  status?: string;
   * }} payload
  */
  async updateStartupRelationship({ dispatch, commit }, { userId, relId, ...payload }) {
    try {
      const response = await axiosCore.patch(`/relationships/startup/${relId}/`, payload);
      successAlert('Relationship updated successfully!');
      commit('setForceFetchRels', true);
      await dispatch('getRelatedStartups', userId);
      commit('setCurrentUserRelsId', userId);
      return response.data;
    } catch (error) {
      errorAlert('Failed to update relationship', error);
    }
  },
  /**
   * Delete UserInvestorRelationship of the user.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {{
   *  userId: number;
   *  relId: number;
   * }} payload
   * @returns {Promise<boolean>}
  */ 
  async deleteInvestorRelationship({ dispatch, commit }, { userId, relId }) {
    try {
      await axiosCore.delete(`/relationships/investor/${relId}/`);
      commit('setForceFetchRels', true);
      await dispatch('getRelatedInvestors', userId);
      commit('setCurrentUserRelsId', userId);
      return successAlert('Relationship deleted successfully!');
    } catch (error) {
      return errorAlert('Failed to delete relationship', error);
    }
  },
  /**
   * Delete UserPartnerRelationship of the user.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {{
   *  userId: number;
   *  relId: number;
   * }} payload
   * @returns {Promise<boolean>}
  */
  async deletePartnerRelationship({ dispatch, commit }, { userId, relId }) {
    try {
      await axiosCore.delete(`/relationships/partner/${relId}/`);
      commit('setForceFetchRels', true);
      await dispatch('getRelatedPartners', userId);
      commit('setCurrentUserRelsId', userId);
      return successAlert('Relationship deleted successfully!');
    } catch (error) {
      return errorAlert('Failed to delete relationship', error);
    }
  },
  /**
   * Delete UserStartupRelationship of the user.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {{
   *  userId: number;
   *  relId: number;
   * }} payload
   * @returns {Promise<boolean>}
  */
  async deleteStartupRelationship({ dispatch, commit }, { userId, relId }) {
    try {
      await axiosCore.delete(`/relationships/startup/${relId}/`);
      commit('setForceFetchRels', true);
      await dispatch('getRelatedStartups', userId);
      commit('setCurrentUserRelsId', userId);
      return successAlert('Relationship deleted successfully!');
    } catch (error) {
      return errorAlert('Failed to delete relationship', error);
    }
  },
  /**
   * Add UserInvestorRelationship of the user.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {{
   *  userId: number;
   *  investorId: number;
   *  is_contact?: boolean;
   *  status?: string;
   * }} payload
   * @returns {Promise<boolean>}
  */
  async addInvestorRelationship({ dispatch, commit }, {
    userId, investorId, is_contact = true, status = 'validated',
  }) {
    try {
      const response = await axiosCore.post(`/investors/${investorId}/relationship/create`, {
        account_id: userId,
        is_contact: is_contact,
        status: status,
      });
      commit('setForceFetchRels', true);
      await dispatch('getRelatedInvestors', userId);
      commit('setCurrentUserRelsId', userId);
      return successAlert('Relationship added successfully!');
    } catch (error) {
      return errorAlert('Failed to add Investor', error);
    }
  },
  /**
   * Add UserPartnerRelationship of the user.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {{
   *  userId: number;
   *  partnerId: number;
   *  is_owner?: boolean;
   *  status?: string;
   * }} payload
   * @returns {Promise<boolean>}
  */
  async addPartnerRelationship({ dispatch, commit }, {
    userId, partnerId, is_owner = true, status = 'validated',
  }) {
    try {
      const response = await axiosCore.post(`/partners/${partnerId}/relationship/create`, {
        account_id: userId,
        is_owner: is_owner,
        status: status,
      });
      commit('setForceFetchRels', true);
      await dispatch('getRelatedPartners', userId);
      commit('setCurrentUserRelsId', userId);
      return successAlert('Relationship added successfully!');
    } catch (error) {
      return errorAlert('Failed to add Partner', error);
    }
  },
  /**
   * Add UserStartupRelationship of the user.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {{
   *  userId: number;
   *  startupId: number;
   *  is_owner?: boolean;
   *  is_contact?: boolean;
   *  is_founder?: boolean;
   *  status?: string;
   * }} payload
   * @returns {Promise<boolean>}
  */
  async addStartupRelationship({ dispatch, commit }, {
    userId, startupId, is_owner = false, is_contact = true, is_founder = false, status = 'validated',
  }) {
    try {
      const response = await axiosCore.post(`/startups/${startupId}/relationship/create`, {
        account_id: userId,
        is_contact: is_contact,
        is_owner: is_owner,
        is_founder: is_founder,
        status: status,
      });
      commit('setForceFetchRels', true);
      await dispatch('getRelatedStartups', userId);
      commit('setCurrentUserRelsId', userId);
      return successAlert('Relationship added successfully!');
    } catch (error) {
      return errorAlert('Failed to add Startup', error);
    }
  },
};

const mutations = {
  /**
   * The user id to associate with the getters.
   * @param {State} state - Implicit parameter for vuex mutations.
   * @param {number} userId - ID of the user.
  */
  setCurrentUserRelsId: (state, userId) => {
    if (!isEmpty(state.userRels[userId])) state.currentUserRelsId = userId;
  },
  /**
   * Updates the user relationship data using supplied fields for the given user ID.
   * @param {State} state - Implicit parameter for vuex mutations.
   * @param {number} userId - ID of the user.
   * @param {Relationships} userRelData - Relationship data to set for the user.
  */
  setUserRelData: (state, { userId, userRelData }) => {
    state.userRels[userId] = { ...state.userRels[userId], ...userRelData };
  },
  /**
   * Flag which defines if cached data should be refetched or not.
   * @param {State} state - Implicit parameter for vuex mutations.
   * @param {boolean} fetch - Whether to force fetch or not.
  */
  setForceFetchRels: (state, fetch) => {
    state.forceFetchRels = fetch;
  },
};

/** @typedef {typeof getters} Getters */
/** @typedef {typeof actions} Actions */
/** @typedef {typeof mutations} Mutations */

/**
 * @module userInfo
 * @typedef {Object} Relationships
 * @property {State} state
 * @property {Getters} getters
 * @property {Actions} actions
 * @property {Mutations} mutations
*/

export default {
  state,
  getters,
  actions,
  mutations,
};
