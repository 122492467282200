<template>
  <div class="back-drop" @mousedown.self="hide">
    <div
      :id="id"
      class="modal-root"
      :ref="name"
      :style="cssStyles"
    >
      <div slot="modal-header" class="modal-header">
        <div class="modal-title">{{ title }}</div>
        <div class="modal-x" @mousedown="hide"><i class="fas fa-times"></i></div>
      </div>
      <div class="content-modal">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Modal',
  props: {
    id: {
      type: String,
      required: false,
      default: 'custom-modal',
    },
    name: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      default: '',
      required: false,
    },
    size: {
      type: String,
      required: false,
      default: 'md',
    },
  },
  computed: {
    width() {
      if (this.size == 'md') { return 'auto'; }
      if (this.size == 'lg') { return '75%'; }
      return this.size;
    },
    cssStyles() {
      return {
        '--width': this.width,
      };
    },
  },
  methods: {
    hide() {
      this.$emit('hidden');
    },
  },
  mounted() {
    document.addEventListener('keydown', (e) => {
      if (e.code === 'Escape') {
        this.hide();
      }
    });
  },
};
</script>

<style lang="scss" scoped>

.back-drop {
  background: rgba(100,100,100,0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1999;
}

.modal-root {
  border-radius: 15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2000;
  max-height: 90vh;
  width: var(--width);
  min-width: fit-content;
  overflow-y: scroll;
  overflow-x: auto;

  box-shadow: 1px 1px 5px 1px $grey-200;
  background-color: white;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 568px) {
    width: 100%;
  }
}

.modal-header {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.modal-title {
  width: 100%;
  padding: 0 0 0 50px;
  text-align: center;
  font: $main-font-regular;
  font-size: 25px;
  color: $black-color;
}

.modal-x {
  font-size: 20px;
  cursor: pointer;
  margin-left: auto;
  width: 50px;
  padding: 15px;
  color: $red-color;
  :hover {
    color: $primary;
  }
}
</style>
