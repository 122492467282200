import { axiosCore } from '@/plugins/axios';
import { errorAlert } from '@/ort-lib/utils/utils.js';
import { getAction } from '@/ort-lib/utils/jsdoc.js';

const successAlert = getAction('successAlert');

const state = {
  document: {},
};

const getters = {
  document: (state) => state.document,
};

const actions = {
  getDocument({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      axiosCore.get(data.value).then(
        (response) => {
          successAlert('Document successfully fetched');
          resolve(response);
          commit('setDocument', { type: data.type, value: response.data, id: data.id?.toString() });
        },
        (error) => {
          errorAlert('There was an error when fetching the document', error);
          reject(error);
        },
      );
    });
  },
  getDocumentSilently({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosCore.get(data.url).then(
        (response) => {
          resolve(response);
          commit('setDocument', { value: response.data });
        },
        (error) => {
          reject(error);
        },
      );
    });
  },
  deleteDocument({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      axiosCore.delete(data.url).then(
        (response) => {
          successAlert('Document successfully deleted');
          resolve(response);
          commit('deleteDocument', { type: data.type, id: data.id.toString() });
        },
        (error) => {
          errorAlert('Error when deleting the document', error);
          reject(error);
        },
      );
    });
  },
  uploadFile({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      axiosCore.post(data.url, data.post_data, {headers:{'Content-Type': 'multipart/form-data'}}).then(
        (response) => {
          successAlert('Document successfully uploaded');
          resolve(response);
          if (response.data) {
            commit('setDocument', { type: data.type, value: response.data, id: data?.id?.toString() });
          }
        },
        (error) => {
          errorAlert('There was an error when uploading the document.', error);
          reject(error);
        },
      );
    });
  },
  async patchDocumentFile({ dispatch }, data) {
    try {
      await axiosCore.patch(`files/document/staff/documentfile/${data.id}/`, data);
      successAlert('Document visibility updated');
    } catch (error) {
      errorAlert('Document visibility not updated', error);
    }
  },
};

const mutations = {
  setDocument: (state, { type, value, id }) => {
    state.document[id] = {
      ...state.document[id],
      [type]: value,
    };
  },
  deleteDocument: (state, { type, id }) => {
    const documentToDelete = Object.keys(state.document).find((documentItemKey) => documentItemKey === id);
    if (documentToDelete) {
      state.document[id] = {
        ...state.document[id],
        [type]: null,
      };
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
