import { axiosCore } from '@/plugins/axios';

const state = {
  events: {
    EUR: [],
    USD: [],
  },
  kpis: {
    EUR: [],
    USD: [],
  },
  isEventsLoading: false,
  isKPIsLoading: false,
  isExportLoading: false,
};

const getters = {
  getEvents: (state) => state.events,
  getKPIs: (state) => state.kpis,
  getIsLoading: (state) => state.isEventsLoading,
  getIsKPIsLoading: (state) => state.isKPIsLoading,
  getIsExportLoading: (state) => state.isExportLoading,
};

const actions = {
  async fetchEvents({ commit, dispatch }, investorId) {
    commit('setIsEventsLoading', true);
    let response;
    try {
      response = await axiosCore.get(`events/investor/${investorId}?currency=EUR`);
      commit('setEventsEUR', response.data);
      response = await axiosCore.get(`events/investor/${investorId}?currency=USD`);
      commit('setEventsUSD', response.data);
    } catch (error) {
      dispatch('dangerAlert', error.message);
    } finally {
      commit('setIsEventsLoading', false);
      return response;
    }
  },

  async fetchKPIs({ commit, dispatch }, investorId) {
    commit('setIsKPIsLoading', true);
    let response;
    try {
      response = await axiosCore.get(`events/investor/${investorId}/kpis?currency=EUR`);
      commit('setKPIsEUR', response.data);
      response = await axiosCore.get(`events/investor/${investorId}/kpis?currency=USD`);
      commit('setKPIsUSD', response.data);
    } catch (error) {
      dispatch('dangerAlert', error.message);
    } finally {
      commit('setIsKPIsLoading', false);
      return response;
    }
  },

  async exportValuations({ commit, dispatch }, { investorId, currency }) {
    let url = `events/investor/${investorId}?currency=${currency}&format=csv`;
    commit('setIsExportLoading', true);
    let response;
    try {
      response = await axiosCore.get(url);
      url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'export.csv');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      dispatch('dangerAlert', error.message);
    } finally {
      commit('setIsExportLoading', false);
      return response;
    }
  },
};

const mutations = {
  setEventsEUR: (state, data) => { state.events.EUR = data; },
  setEventsUSD: (state, data) => { state.events.USD = data; },
  setKPIsEUR: (state, data) => { state.kpis.EUR = data; },
  setKPIsUSD: (state, data) => { state.kpis.USD = data; },
  setIsEventsLoading: (state, data) => { state.isEventsLoading = data; },
  setIsKPIsLoading: (state, data) => { state.isKPIsLoading = data; },
  setIsExportLoading: (state, data) => { state.isExportLoading = data; },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
