<template>
  <router-link
    v-if="!external"
    :class="' link-' + this.computed_color"
    :to="to"
    @click="clicked"
    ><slot></slot
  ></router-link>
  <a
    v-else
    :class="this.empty + ' ' + 'link-' + color"
    :href="to"
    :target="newTab ? '_blank' : ''"
    @click="clicked"
    ><slot></slot
  ></a>
</template>

<script>
export default {
  name: 'Link',
  props: {
    color: {
      type: String,
      required: false,
      default: 'blue',
    },
    to: {
      required: false,
    },
    external: {
      type: Boolean,
      required: false,
      default: false,
    },
    'new-tab': {
      type: Boolean,
      required: false,
      default: true,
    },
    empty: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    computed_color() {
      return this.color;
    },
  },
  data() {
    return {};
  },
  methods: {
    clicked() {
      this.$emit('click');
      if (this.to != undefined && !this.external) {
        this.$router.push(this.to);
      } else {
        return '';
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

.link-card {
  cursor: pointer;
  text-decoration: none;
  color: $black-grey-color;
  &:focus {
    border: none;
    outline: none;
  }
}

.link-void {
  cursor: pointer;
  color: $grey-color;
  text-decoration: none;
  &:focus {
    border: none;
    outline: none;
  }
}

.link {
  cursor: pointer;
  color: $grey-color;
  font: $main-font-regular;
  align-items: flex-start;
  vertical-align: top;
  transition: color 0.3s ease;
  border: none;
  margin: auto;
  &:focus {
    border: none;
    outline: none;
  }
}

.link-grey {
  color: $grey-color;
  &:hover,
  &:focus,
  &:active {
    color: $black-grey-color;
  }
}

.link-white {
  color: $white-color;
  &:hover,
  &:focus,
  &:active {
    color: $white-grey-color;
  }
}

.link-table {
  font: $main-font-bold;
  font-size: 12px;
  cursor: pointer;
  color: $red-color;
  text-decoration: none;
  &:hover,
  &:focus,
  &:active {
    border: none;
    outline: none;
  }
}

.link-red {
  color: $red-color;
  &:hover,
  &:focus,
  &:active {
    color: $red-darker-color;
  }
}

.link-green {
  color: $green-color;
  &:hover,
  &:focus,
  &:active {
    color: $green-darker-color;
  }
}

.link-blue {
  color: $blue-color;
  &:hover,
  &:focus,
  &:active {
    color: $blue-darker-color;
  }
}

.link-yellow {
  color: $yellow-color;
  &:hover,
  &:focus,
  &:active {
    color: $yellow-darker-color;
  }
}

.link-purple {
  color: $purple-color;
  &:hover,
  &:focus,
  &:active {
    color: $purple-darker-color;
  }
}
</style>
