import { axiosCore } from '@/plugins/axios';

const state = {
  allUsers: [],
  IsAllUsersTableLoading: false,
  allUsersCount: 0,
};

const getters = {
  getAllUsers: (state) => state.allUsers,
  getIsAllUsersTableLoading: (state) => state.IsAllUsersTableLoading,
  getAllUsersCount: (state) => state.allUsersCount,
};

const mutations = {
  setAllUsers: (state, data) => {
    state.allUsers = data;
  },
  setIsAllUsersTableLoading: (state, payload) => {
    state.IsAllUsersTableLoading = payload;
  },
  setAllUsersCount: (state, payload) => {
    state.allUsersCount = payload;
  },
};

const actions = {
  async fetchAllUsers({ commit, dispatch }, {
    perPage, page, searchParam, selectedFilters,
  }) {
    commit('setIsAllUsersTableLoading', true);
    try {
      const response = await axiosCore.get(`/users/list-core?${searchParam}page=${page}&page_size=${perPage}${selectedFilters}`);
      commit('setAllUsers', response.data?.results);
      commit('setAllUsersCount', response.data?.count);
    } catch (error) {
      dispatch('triggerAlert', {
        show: true,
        type: 'danger',
        message: error.message,
      });
    } finally {
      commit('setIsAllUsersTableLoading', false);
    }
  },
  async exportUsersTable({ dispatch }, {
    perPage, page, searchParam, selectedFilters,
  }) {
    try {
      const response = await axiosCore.get(`/users/list-core?${searchParam}page=${page}&page_size=${perPage}${selectedFilters}&format=csv`);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'export.csv');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      dispatch('triggerAlert', {
        show: true,
        type: 'danger',
        message: error.message,
      });
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
