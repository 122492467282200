<template>
  <div class="div-radio">
    <div class="field-header">
      <div class="d-flex icon-hover-wrapper">
        <div v-if="label !== null" class="mb-3">
          <span v-if="requiredField" class="required-field-asterisc text">* </span>
          <span>{{ label }}</span>
        </div>
      </div>
    </div>
    <div v-if="!edit">
      <span v-show="showingValue" class="radio-info">
        <i class="fas fa-check checked-icon"></i>
        <p class="radio-text">Yes</p>
      </span>
      <span v-show="!showingValue" class="radio-info">
        <i class="fas fa-times unchecked-icon"></i>
        <p class="radio-text">No</p>
      </span>
    </div>
    <div v-if="edit">
      <div class="form-check mb-0">
        <div class="custom-control custom-radio custom-control-inline">
          <input
            :id="`${field}1`"
            class="form-check-input custom-control-input"
            type="radio"
            :disabled="disabled"
            :value="true"
            :name="field"
            v-model="showingValue"
            @checked="this.initialValue === true"
            @input="updateValue(true)"
          >
          <label class="form-check-label custom-control-label" :for="`${field}1`">
            <i class="fas fa-check checked-icon"></i>
          </label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
          <input
            :id="`${field}2`"
            class="form-check-input custom-control-input"
            type="radio"
            :disabled="disabled"
            :name="field"
            :value="false"
            v-model="showingValue"
            @checked="this.initialValue === false"
            @input="updateValue(false)"
          >
          <label class="form-check-label custom-control-label" :for="`${field}2`">
            <i class="fas fa-times unchecked-icon"></i>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormRadio',
  props: {
    field: {
      type: String,
      required: false,
      default: '',
    },
    parent: {
      type: String,
      required: false,
      default: null,
    },
    editable: {
      type: Boolean,
      required: false,
      default: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    initialValue: {
      required: false,
      default: '',
    },
    state: {
      type: String,
      required: false,
      default: 'null',
    },
    feedback: {
      type: String,
      required: false,
      default: '',
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    needed_label: {
      type: Boolean,
      required: false,
      default: false,
    },
    requiredField: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      value: '',
      edit: this.editable,
      showingValue: this.initialValue,
    };
  },
  watch: {
    state(newVal) {
      this.state = newVal;
    },
    feedback(newVal) {
      this.feedback = newVal;
    },
    initialValue(newVal) {
      this.value = newVal;
    },
    editable(new_val) {
      this.edit = new_val;
      this.value = this.initialValue;
      this.showingValue = this.initialValue;
    },
  },
  methods: {
    updateValue(value) {
      this.value = value;
      this.$emit('update:modelValue', this.value, this.field, this.parent, this.requiredField, true);
    },
    clicked() {
      this.$emit('clicked');
    },
    openField() {
      this.edit = true;
    },
    closeField() {
      this.edit = false;
    },
    saveField() {
      this.edit = false;
      this.$emit('save', this.value, this.field, this.parent);
    },
    customLabel(option) {
      return `${option.name}`;
    },
  },
  created() {
    this.value = this.initialValue;
  },
};
</script>

<style lang="scss" scoped>

.div-radio {
  width: 100%;
  min-height: 70px;
  margin-bottom: 10px;
  .radio-info {
    display: flex;
    flex-direction: row;
    .radio-text {
      font-size: $medium-font-size;
      font-weight: 400;
      color: $black-color;
      padding-left: 10px;
    }
  }
  .field-header {
    .icon-hover-wrapper {
      width: fit-content;
      &:hover .icon-info {
        visibility: visible !important;
        opacity: 1 !important;
      }
      .edit-icons {
        display: flex;
        align-items: center;
        &-image {
          justify-content: center;
        }
        .icon-link {
          margin-left: 5px;
          height: 23px;
          width: 23px;
          color: $red-color;
          border-radius: 100%;
          padding: 5px;
          transition: color 0.3s ease, background 0.3s ease;
          &:hover,
          &:focus,
          &:active {
            background-color: rgba($red-lighter-color, 0.6);
          }
        }
        .icon-info {
          margin-left: 5px;
          height: 15px;
          width: 15px;
          color: $black-color;
          visibility: hidden;
          opacity: 0;
          transition: all 0.3s ease;
        }
        .dual-icons {
          display: flex;
          flex-flow: row;
        }
      }
    }
  }
  .label {
    text-align: left;
    font-size: $medium-font-size;
    margin-bottom: 5px;
    font-weight: 700;
  }
  .radio {
    min-height: 35px !important;
  }

  i::before {
    cursor: pointer;
  }

}
.required-field-asterisc {
  color: $red-color;
}
</style>
